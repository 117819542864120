/**
 * @name MainContainer
 * Main container for the vista, this also controls any content that appears over the top of it.
 */

import React, { useContext, useEffect, useState } from "react";
// import AppContext from "../../contexts/AppContext";
import Vista from "../../components/vista";
import OverlayContainer from "../../components/overlayContainer";
// import Menu from "../../components/menu";
import { Wrapper, Container, MenuContainer } from "./styles";
import { Menu, Dropdown, Avatar, Tooltip } from "antd";

import Brands from "../../../../ExpoMap/Brands";
import { DownOutlined } from "@ant-design/icons";

import { ExpoHallSvg } from "../../../../../components/svgJS/ExpoHallSvg";

import { Menu1 } from "../../../../../components/svgJS/Menu1";

import { Calendar3 } from "../../../../../components/svgJS/Calendar3";
import { Conference } from "../../../../../components/svgJS/Conference";
import { Meeting } from "../../../../../components/svgJS/Meeting";

import AppContext, { ACTIONS } from "../../contexts/AppContext";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import { setHeaderData } from "../../../../../redux/slice/headerData";
import { ApiCall } from "../../../../../utils/api";

import { hallData, standsData } from "../../apiData";
import {
  setConcourseData,
  setExpoHallUrl,
  setExpoMapRedirectLinkName,
  setFromBrandVisitBooth,
  setNetworkingEntryGateLocationNumber,
  setResetVistaBoothData,
  setVisitBoothData,
  setVistaBrandData,
} from "../../../../../redux/slice/vista";
import { getConcourseDetails } from "../popup/Api/api";
import moment from "moment";
import ProfileModal from "../../../../common/Modal/ProfileModal";

import { NetworkingLounge } from "../../../../../components/svgJS/NetworkingLounge";
import { toast } from "react-toastify";
import CommonLoader from "../../../../../components/Widgets/CommonLoader";
import placeholder_image from "../../../../../assets/images/profile_placeholder.png";
import { getCookie } from "../../../../../utils/commonFunctions";

const MainContainer = (props) => {
  let CryptoJS = require("crypto-js");
  let user_type = "";
  let user_id = null;
  let socket = props?.props?.socket;

  const dispatchRedux = useDispatch();
  let [selectedMenuItem, setSelectedMenuItem] = useState("");
  const [previousCurrentPosition, setPreviousCurrentPosition] = useState("");

  const { activeWindow, currentPosition } = useContext(AppContext);

  const { dispatch } = useContext(AppContext);

  const expomap_redirect_path = useSelector(
    (state) => state?.vistaBrand?.expomap_redirect_link_name
  );

  const boothData = useSelector((state) => state?.vistaBrand?.boothData);

  const fromBrandVisitBooth = useSelector(
    (state) => state?.vistaBrand?.fromBrandVisitBooth
  );

  const concourseData = useSelector(
    (state) => state?.vistaBrand?.concourseData
  );

  let expoHallLocationNumber = useSelector(
    (state) => state?.vistaBrand?.expoHallLocationNumber
  );

  let boothLocationNumber = useSelector(
    (state) => state?.vistaBrand?.boothLocationNumber
  );

  let networkingEntryGateLocationNumber = useSelector(
    (state) => state?.vistaBrand?.networkingEntryGateLocationNumber
  );
  const vistaEventId = useSelector((state) => state?.vistaBrand?.eventId); //from Vista redux
  const brandObj = useSelector((state) => state?.vistaBrand?.brandObj); //from Vista redux
  const resetVistaBoothData = useSelector(
    (state) => state?.vistaBrand?.resetVistaBoothData
  );

  let [updateMenuItems, setUpdateMenuItems] = useState(false);

  let [userNearbyArr, setUserNearbyArr] = useState([]);
  let [userData, setUserData] = useState(null);
  const [isProfileModalVisible, setIsProfileModalVisible] = useState(false);
  const [loader, setLoader] = useState(false);
  const [vistaUserLocation, setVistaUserLocation] = useState(null);

  const [locationNumberSatellite, setLocationNumberSatellite] = useState(null);

  if (localStorage.getItem("user_type")) {
    const ciphertext = localStorage.getItem("user_type");
    let bytes = CryptoJS.AES.decrypt(ciphertext, "user_type");
    user_type = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }

  if (getCookie("user_id")) {
    const ciphertext = getCookie("user_id");
    let bytes = CryptoJS.AES.decrypt(ciphertext, "user_id");
    user_id = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }

  useEffect(() => {
    const fetchLocation = (nearyByData) => {
      let event_id = localStorage.getItem("event_id");

      if (user_type === "SUPER ADMIN" || user_type === "CUSTOMER") {
        event_id = vistaEventId;
      }

      console.log("nearby data", nearyByData);
      console.log(
        nearyByData?.data?.event_id,
        event_id,
        nearyByData?.data?.event_id === event_id,
        "compare event id"
      );
      if (nearyByData?.data?.event_id === event_id && vistaUserLocation) {
        let userDetails = [];
        if (nearyByData?.data?.details?.length > 0) {
          userDetails = nearyByData?.data?.details?.filter((item) => {
            console.log(
              vistaUserLocation,
              item?.location,
              vistaUserLocation === item?.location,
              "nearyby location"
            );
            if (
              item?.user_id !== parseInt(user_id) &&
              item?.location === vistaUserLocation
            ) {
              return item;
            }
          });
        } else {
          userDetails = [];
        }

        console.log("nearby userDetails", userDetails);
        getUserLocationVista(userDetails);
      }
    };

    if (socket) {
      socket?.on("fetchLocation", fetchLocation);
    }

    return () => {
      socket?.off("fetchLocation", fetchLocation);
    };
  }, [socket, vistaUserLocation]);

  useEffect(() => {
    if (props.props.location.pathname === "/login") {
      setSelectedMenuItem("0");
    } else if (
      props.props.location.pathname === "/expomap" ||
      props.props.location.pathname === "/concourse"
    ) {
      setLoader(true);
      getConcourseData();
    }
    console.log("resetBooth value in useEffect", resetVistaBoothData);
    return () => {
      console.log("resetbooth data called 2");

      dispatchRedux(
        setVisitBoothData({
          tier: null,
          sequence: null,
          final_booth_url: null,
        })
      );
      dispatchRedux(
        setVistaBrandData({
          brand_id: null,
        })
      );

      hallData[0].stands?.forEach((item) => {
        item.status = false;
        item.standUrl = null;
        item.brand_id = null;
        item.standID = null;
      });
    };
  }, [props.props.location.pathname]);

  console.log("selectedMenuItem pppppp", selectedMenuItem);
  console.log("fromBrandVisitBooth pppp", fromBrandVisitBooth);

  useEffect(() => {
    let event_settings = concourseData?.event_settings;
    let concourse_url = event_settings?.concourse_url;

    let exterior_url =
      localStorage.getItem("exterior_url") ?? event_settings?.exterior_url;
    let main_hall_url = event_settings?.main_hall_url;

    if (selectedMenuItem === "0") {
      dispatch({
        type: ACTIONS.SET_VISTA_SRC,
        value: {
          src: exterior_url,
          // src: "efs/vista/defaultVista/exterior-publish/index.html",
          currentPosition: "exteriorPublished",
        },
      });
      if (props.props.location.pathname === "/expomap") {
        dispatch({
          type: ACTIONS.SET_ACTIVE_WINDOW,
          value: `expomap`,
        });
      }
    } else if (selectedMenuItem === "1") {
      if (boothData?.tier) {
        redirectToBrandsBooth();
      } else if (expomap_redirect_path?.linkName) {
        checkForRedirect();
      } else {
        dispatch({
          type: ACTIONS.SET_VISTA_SRC,
          value: {
            src: `${concourse_url}?media-name=6`,
            currentPosition: "concoursePublished",
          },
        });
        dispatch({
          type: ACTIONS.SET_ACTIVE_WINDOW,
          value: `expomap`,
        });
      }
    } else if (selectedMenuItem === "3") {
      dispatch({
        type: ACTIONS.SET_VISTA_SRC,
        value: {
          src: `${concourse_url}?media-name=2`,
          currentPosition: "concoursePublished",
        },
      });
    } else if (selectedMenuItem === "4") {
      if (boothData?.tier) {
        redirectToBrandsBooth();
      } else if (expomap_redirect_path?.linkName) {
        checkForRedirect();
      } else {
        dispatch({
          type: ACTIONS.SET_ACTIVE_WINDOW,
          value: ``,
        });
        dispatch({
          type: ACTIONS.SET_VISTA_SRC,
          value: {
            src: concourse_url,
            currentPosition: "concoursePublished",
          },
        });
      }
    } else if (selectedMenuItem === "5") {
      dispatch({
        type: ACTIONS.SET_VISTA_SRC,
        value: {
          src: main_hall_url,
          currentPosition: "auditoriumPublished",
        },
      });
    } else if (selectedMenuItem === "9") {
      dispatch({
        type: ACTIONS.SET_VISTA_SRC,
        value: {
          src: exterior_url,
          currentPosition: "exteriorPublished",
        },
      });
    }
    // else {
    //   console.log("insideElse pppp 1111")
    //   if(fromBrandVisitBooth){
    //     console.log("insideElse pppp 2222")
    //     if (boothData?.tier) {
    //       console.log("insideElse pppp 3333")
    //       redirectToBrandsBooth();
    //       dispatch(setFromBrandVisitBooth(false))
    //     }
    //   }
    // }
  }, [
    selectedMenuItem,
    concourseData,
    vistaEventId,
    updateMenuItems,
    fromBrandVisitBooth,
    boothData,
  ]);

  useEffect(async () => {
    if (vistaUserLocation && window.location.pathname !== "/login") {
      let event_id = null;

      if (user_type === "SUPER ADMIN" || user_type === "CUSTOMER") {
        event_id = vistaEventId;
      } else {
        event_id = localStorage.getItem("event_id");
      }

      if (vistaUserLocation !== previousCurrentPosition) {
        console.log("updateLocation called uuuu 2222", {
          location: vistaUserLocation,
          event_id: event_id,
          user_id: user_id,
          user_type: user_type,
        });
        socket.emit(
          "updateLocation",
          JSON.stringify({
            location: vistaUserLocation,
            event_id: event_id,
            user_id: user_id,
            user_type: user_type,
          })
        );
      }

      setPreviousCurrentPosition(vistaUserLocation);
    }
  }, [vistaUserLocation]);

  useEffect(() => {
    if (currentPosition) {
      if (currentPosition === "satellitePublished") {
        if (locationNumberSatellite) {
          setVistaUserLocation(locationNumberSatellite);
        }
      } else if (currentPosition === "networkingPublished") {
        if (networkingEntryGateLocationNumber) {
          setVistaUserLocation(networkingEntryGateLocationNumber);
        }
      } else if (currentPosition === "hallPublished") {
        if (expoHallLocationNumber) {
          setVistaUserLocation(currentPosition + expoHallLocationNumber);
        }
      } else if (currentPosition === "standPublished") {
        if (boothLocationNumber) {
          setVistaUserLocation(currentPosition + boothLocationNumber);
        }
      } else {
        setVistaUserLocation(currentPosition);
      }
    }
  }, [
    currentPosition,
    locationNumberSatellite,
    expoHallLocationNumber,
    boothLocationNumber,
    networkingEntryGateLocationNumber,
  ]);

  const getConcourseData = async () => {
    let event_id = localStorage.getItem("event_id");
    if (user_type === "SUPER ADMIN" || user_type === "CUSTOMER") {
      if (vistaEventId) {
        event_id = vistaEventId;
        localStorage.setItem("event_id", event_id);
      }
    }

    let timeStamp = moment().format("YYYY-MM-DD HH:mm:ss");

    let response = await getConcourseDetails({
      event_id: event_id,
      current_timestamp: timeStamp,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });

    if (response && response.data.code === 200) {
      if (response?.data?.data) {
        dispatchRedux(setConcourseData(response?.data?.data));

        if (window.location.pathname === "/expomap") {
          setSelectedMenuItem("1");
        } else if (window.location.pathname === "/concourse") {
          setSelectedMenuItem("4");
        }
      }
    } else {
      toast.error(response?.data?.message);
    }

    setLoader(false);
  };

  const getUserLocationVista = async (userDetails) => {
    setUserNearbyArr([]);

    userDetails?.forEach((user) => {
      setUserNearbyArr((prev) => [
        ...prev,
        {
          user_name: user?.first_name + " " + user?.last_name,
          ...user,
        },
      ]);
    });
  };

  const getExpoHallDetails = async (event_id) => {
    const response = await ApiCall(
      "GET",
      "",
      "/admin/expohallsmap/details/get",
      event_id
    );
    if (response?.data?.code === 200) {
      return response.data.data;
    } else {
      return [];
    }
  };

  const redirectToBrandsBooth = async () => {
    let selectedStand = standsData[boothData?.tier - 1];
    // selectedStand.url =
    //   "efs/vista/43/5e908c7dcae86f1c905ae40389a784ea/booth-publish/diamond/index.html";
    console.log(
      "pppp boothData in mainContainer",
      boothData,
      standsData[boothData?.tier - 1]
    );

    selectedStand.url = boothData?.final_booth_url;
    let newHotspot = standsData[boothData?.tier - 1]?.hotspots?.map(
      (hotspot) => ({
        ...hotspot,
        status: false,
      })
    );

    if (boothData?.zones?.length !== 0) {
      boothData?.zones?.forEach((item) => {
        if (item?.booth_location_id) {
          let sameIndex = standsData[boothData?.tier - 1]?.hotspots?.findIndex(
            (val) => val.index === item.booth_location_id
          );
          if (sameIndex !== -1) {
            newHotspot[sameIndex].status = item?.is_active;
          }
        }
      });
    }

    console.log("pppp new hotspot", newHotspot);
    selectedStand.lookAtBooth = 1;
    selectedStand.exit = 1;
    selectedStand.hotspots = newHotspot;

    initializeHallDataValues("booth");

    console.log("selected Stand pppp", selectedStand);

    dispatch({
      type: ACTIONS.SET_STAND, // responsible for setting stand
      value: selectedStand,
    });
  };

  const checkForRedirect = async () => {
    //Logic to Redirect from Event calendar button to specific path in vista

    if (
      expomap_redirect_path.linkName &&
      expomap_redirect_path.notFromExpoMap
    ) {
      switch (expomap_redirect_path.linkName) {
        case "ExpoHall":
          let event_id = null;
          if (user_type === "SUPER ADMIN" || user_type === "CUSTOMER") {
            if (vistaEventId) {
              event_id = vistaEventId;
              localStorage.setItem("event_id", event_id);
            }
          } else {
            event_id = localStorage.getItem("event_id");
          }

          let timeStamp = moment().format("YYYY-MM-DD HH:mm:ss");

          let response = await getConcourseDetails({
            event_id: event_id,
            current_timestamp: timeStamp,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          });

          if (response && response.data.code === 200) {
            if (response?.data?.data) {
              let expoHallNumber = expomap_redirect_path?.number;

              initializeHallDataValues("eventCal", expoHallNumber);

              let getCurrentExpoHall =
                response?.data?.data?.event_settings?.event_settings_expo_hall_details?.find(
                  (item) => {
                    if (item?.sequence === expoHallNumber) {
                      return item?.expo_hall_url;
                    }
                  }
                );

              hallData[0].url = getCurrentExpoHall?.expo_hall_url;
              dispatch({
                type: ACTIONS.SET_ACTIVE_WINDOW,
                value: "",
              });

              dispatch({
                type: ACTIONS.SET_HALL,
                value: hallData[0],
              });
              clearExpoMapRedirectPath();
            }
          }

          break;
        case "MainAuditorium":
          dispatch({
            type: ACTIONS.SET_VISTA_SRC,
            value: {
              src: concourseData?.event_settings?.main_hall_url,
              currentPosition: "auditoriumPublished",
            },
          });
          clearExpoMapRedirectPath();
          break;
        case "SATAuditorium":
          concourseData?.event_settings?.event_settings_satellite_auditorium_details?.forEach(
            (item, i) => {
              if (item?.sequence === expomap_redirect_path?.number) {
                dispatch({
                  type: ACTIONS.SET_VISTA_SRC,
                  value: {
                    src: item?.sat_audi_hall_url,
                    currentPosition: `satellitePublished`,
                  },
                });
              }
            }
          );
          clearExpoMapRedirectPath();
          break;
        case "Lounge":
          concourseData?.event_settings?.event_settings_networking_lounge_details?.forEach(
            (item, i) => {
              if (item?.sequence === expomap_redirect_path?.number) {
                dispatch({
                  type: ACTIONS.SET_VISTA_SRC,
                  value: {
                    src: item?.networking_url,
                    currentPosition: "networkingPublished",
                  },
                });
              }
            }
          );
          clearExpoMapRedirectPath();
          break;
      }
    }
  };

  const initializeHallDataValues = async (flag, expoHallNumber = null) => {
    let event_id = null;

    if (user_type === "SUPER ADMIN" || user_type === "CUSTOMER") {
      event_id = vistaEventId;
    } else {
      event_id = localStorage.getItem("event_id");
    }

    let responseData = await getExpoHallDetails({
      event_id: event_id,
    });

    let singleExpoHall = null;

    if (flag === "booth") {
      singleExpoHall = responseData?.find((expohall) => {
        return expohall?.expo_hall_mappings?.find((obj) => {
          if (obj?.brand_id === brandObj?.brand_id) {
            return expohall;
          }
        });
      });
    } else if (flag === "eventCal") {
      if (expoHallNumber) {
        singleExpoHall = responseData?.find((expohall) => {
          let expoHallNumber2 = expohall?.hall_title.split(" ")[2];
          if (expoHallNumber === expoHallNumber2) {
            return expohall;
          }
        });
      }
    }

    let getExpoHallNumber = singleExpoHall?.hall_title.split(" ")[2];
    let expoMappingArr = [];
    if (getExpoHallNumber % 2 === 0) {
      expoMappingArr = singleExpoHall?.expo_hall_mappings;
    } else {
      expoMappingArr = singleExpoHall?.expo_hall_mappings.reverse();
    }

    expoMappingArr = expoMappingArr?.map((elem, i) => {
      return {
        ...elem,
        isElementPresent: true,
      };
    });

    if (flag === "booth") {
      dispatchRedux(setExpoHallUrl(singleExpoHall?.expo_hall_url));
      hallData[0].url = singleExpoHall?.expo_hall_url;
    }

    expoMappingArr?.forEach((val) => {
      hallData[0].stands[val.sequence - 1].status = val?.isElementPresent;
      hallData[0].stands[val.sequence - 1].standID = val?.brand_tier;
      hallData[0].stands[val.sequence - 1].standUrl = val?.final_booth_url;

      hallData[0].stands[val.sequence - 1].brand_id = val?.brand_id;
      hallData[0].stands[val.sequence - 1].zones = val?.zones;
      hallData[0].stands[val.sequence - 1].brand_tier = val?.brand_tier;
    });
  };

  const clearExpoMapRedirectPath = () => {
    dispatchRedux(
      setExpoMapRedirectLinkName({
        linkName: "",
        number: "",
        notFromExpoMap: false,
      })
    );
  };

  const componentsSwitch = (key) => {
    switch (key) {
      case "2":
        return (
          <div className="expo_item_wrapper">
            <Brands
              historyFromVista={props?.props?.history}
              isFromVista={true}
              setSelectedMenuItem={() => {
                setTimeout(() => {
                  setSelectedMenuItem("4");
                }, 500);
              }}
            />
          </div>
        );
      case "0":
      case "1":
      case "3":
      case "4":
      case "5":
      case "6":
      case "7":
      case "9":
        return <Vista props={props} />;
      case "8":
        props?.props?.history?.push("/eventCalendar");
        return;
      default:
        return <Vista props={props} />;
        break;
    }
  };

  const handleMenuClick = (e) => {
    setSelectedMenuItem(e.key);
    setLoader(true);

    if (e.key === selectedMenuItem) {
      setUpdateMenuItems(!updateMenuItems);
      let event_id;
      if (user_type === "SUPER ADMIN" || user_type === "CUSTOMER") {
        event_id = vistaEventId;
      } else {
        event_id = localStorage.getItem("event_id");
      }
      console.log("updateLocation called uuuu 3333", {
        location: vistaUserLocation,
        event_id: event_id,
        user_id: user_id,
        user_type: user_type,
      });
      socket.emit(
        "updateLocation",
        JSON.stringify({
          location: vistaUserLocation,
          event_id: event_id,
          user_id: user_id,
          user_type: user_type,
        })
      );
    } else {
      switch (e.key) {
        case "1":
          dispatchRedux(
            setHeaderData({
              title: "Expo Map",
              breadcrumbs: ["Expo Map", "Expo Halls"],
            })
          );
          break;
        case "2":
          dispatchRedux(
            setHeaderData({
              title: "Expo Map",
              breadcrumbs: ["Expo Map", "Brands"],
            })
          );
          break;
        case "3":
          dispatchRedux(
            setHeaderData({
              title: "Expo Map",
              breadcrumbs: ["Expo Map", "Reception"],
            })
          );
          break;
        case "4":
          // setSelectedMenuItem("4");
          dispatchRedux(
            setHeaderData({
              title: "Expo Map",
              breadcrumbs: ["Expo Map", "Concourse"],
            })
          );
          break;
        case "5":
          // setSelectedMenuItem("5");
          dispatchRedux(
            setHeaderData({
              title: "Expo Map",
              breadcrumbs: ["Expo Map", "Main Auditorium"],
            })
          );
          break;
        case "6":
          dispatchRedux(
            setHeaderData({
              title: "Expo Map",
              breadcrumbs: ["Expo Map", "Satellite Auditorium"],
            })
          );
          break;
        case "7":
          // setSelectedMenuItem("7");
          dispatchRedux(
            setHeaderData({
              title: "Expo Map",
              breadcrumbs: ["Expo Map", "Networking Lounges"],
            })
          );
          break;
        case "8":
          dispatchRedux(
            setHeaderData({
              title: "Expo Map",
              breadcrumbs: ["Expo Map", "Event Calendar"],
            })
          );
          break;
        default:
          break;
      }
    }
    setTimeout(() => {
      setLoader(false);
    }, 500);
  };

  const getSatelliteAudiArr = () => {
    let arr = [];

    let event_id = localStorage.getItem("event_id");

    if (user_type === "SUPER ADMIN" || user_type === "CUSTOMER") {
      event_id = vistaEventId;
    }

    concourseData?.event_settings?.event_settings_satellite_auditorium_details?.forEach(
      (item, i) => {
        arr.push({
          label: `SAT AUDI ${i + 1}`,
          key: `sat_audi_${i + 1}`,
          onClick: () => {
            setLocationNumberSatellite(`sat_audi_${i + 1}`);
            dispatch({
              type: ACTIONS.SET_VISTA_SRC,
              value: {
                src: item?.sat_audi_hall_url,
                currentPosition: `satellitePublished`,
              },
            });
          },
        });
      }
    );

    return arr;
  };

  const getNetworkingArr = () => {
    let arr = [];

    concourseData?.event_settings?.event_settings_networking_lounge_details?.forEach(
      (item, i) => {
        arr.push({
          label: `Networking Lounge ${i + 1}`,
          key: `net_lounge_${i + 1}`,
          onClick: () => {
            dispatchRedux(
              setNetworkingEntryGateLocationNumber(`net_lounge_${i + 1}`)
            );

            dispatch({
              type: ACTIONS.SET_VISTA_SRC,
              value: {
                src: item?.networking_url,
                currentPosition: "networkingPublished",
              },
            });
          },
        });
      }
    );

    return arr;
  };

  const menu = (
    <Menu
      className=""
      onClick={handleMenuClick}
      items={[
        {
          label: <span className="ms-3 ">Expo Halls</span>,
          key: "1",
          icon: <ExpoHallSvg />,
          className: "expomap_menu_list",
          disabled: !concourseData?.event_settings?.is_expo_hall_active
            ? true
            : false,
        },
        {
          label: <span className="ms-3">Brands</span>,
          key: "2",
          icon: <Menu1 />,
          className: "expomap_menu_list",
        },
        {
          label: <span className="ms-3">Reception</span>,
          key: "3",
          icon: <Menu1 />,
          className: "expomap_menu_list",
          disabled: !concourseData?.event_settings?.is_expo_hall_active
            ? true
            : false,
        },
        {
          label: <span className="ms-3">Concourse</span>,
          key: "4",
          icon: <Meeting />,
          className: "expomap_menu_list",
          disabled: !concourseData?.event_settings?.is_concourse_active
            ? true
            : false,
        },
        {
          label: <span className="ms-3">Main Auditorium</span>,
          key: "5",
          icon: <Conference />,
          className: "expomap_menu_list",
          disabled: !concourseData?.event_settings?.is_main_hall_active
            ? true
            : false,
        },
        {
          label: <span className="ms-3">Satellite Auditorium</span>,
          key: "6",
          icon: <Meeting />,
          className: "expomap_menu_list",
          disabled: !concourseData?.event_settings?.is_sat_auditorium_active
            ? true
            : false,
          children: getSatelliteAudiArr(),
        },
        {
          label: <span className="ms-3"> Networking Lounges</span>,
          key: "7",
          icon: <NetworkingLounge />,
          className: "expomap_menu_list",
          disabled: !concourseData?.event_settings?.is_networking_active
            ? true
            : false,
          children: getNetworkingArr(),
        },
        {
          label: <span className="ms-3">Event Calendar</span>,
          key: "8",
          icon: <Calendar3 />,
          className: "expomap_menu_list",
        },
        {
          label: <span className="ms-3">Exterior</span>,
          key: "9",
          icon: <ExpoHallSvg />,
          className: "expomap_menu_list",
        },
      ]}
    />
  );

  console.log("resetBooth value in main container", resetVistaBoothData);

  if (loader) return <CommonLoader />;
  else
    return (
      <>
        <Wrapper isLogin={props.props.location.pathname}>
          {/* <Menu /> */}
          <MenuContainer>
            {props.props.location.pathname !== "/login" && (
              <Dropdown
                overlayClassName="expomap_menu"
                className="ms-2"
                overlay={menu}
              >
                <button className="w-100 vista_expomap_btn">
                  <span className="">Quick jump to</span>
                  <DownOutlined />
                </button>
              </Dropdown>
            )}
          </MenuContainer>
          <Container>
            {/** React overlay */}
            {activeWindow && <OverlayContainer props={props} />}

            {/** Vista */}

            {componentsSwitch(selectedMenuItem)}

            {/* <Vista url={props.url} /> */}
          </Container>

          <div
            style={{
              height: "auto",
              maxHeight: "430px",
              width: "50px",
              overflow: "auto",
              position: "fixed",
              right: 0,
            }}
            className="bg-white   ms-2 mt-5 "
            id="net_tooltip"
          >
            {userNearbyArr &&
              userNearbyArr.map((user) => {
                return (
                  <div
                    key={"usernearby_" + user.user_id}
                    className="networking_tooltip   p-1"
                    onClick={() => {
                      setUserData(user);
                      setIsProfileModalVisible(true);
                    }}
                  >
                    <Tooltip
                      placement="left"
                      title={() => (
                        <div className="d-flex p-2 networking_tooltipContent">
                          <div>
                            <span
                              className="fs-18 text-nowrap near_by_user_name"
                              title={user?.user_name}
                            >
                              {user?.user_name}
                            </span>
                            <br />
                            <span>{user?.company}</span>
                          </div>
                          <Avatar
                            shape="square"
                            size={64}
                            className="ms-2"
                            src={user?.image_url ?? placeholder_image}
                          />
                        </div>
                      )}
                    >
                      <Avatar
                        shape="square"
                        src={user?.image_url ?? placeholder_image}
                      />
                    </Tooltip>
                  </div>
                );
              })}
          </div>
          <ProfileModal
            isProfileModalVisible={isProfileModalVisible}
            userData={userData}
            changeModalFlag={() => {
              setIsProfileModalVisible(false);
            }}
          />
        </Wrapper>
      </>
    );
};

export default MainContainer;
