import React, { useEffect } from "react";
import "./index.css";
import { Breadcrumb, Button, Empty, Modal } from "antd";
import { Input } from "antd";
import {
  SearchOutlined,
  CloseOutlined,
  ExclamationOutlined,
} from "@ant-design/icons";
import { BoothRed } from "../../components/svgJS/BoothRed";
import { User } from "../../components/svgJS/User";
import { SendRed } from "../../components/svgJS/SendRed";
import { Video } from "../../components/svgJS/Video";
import { CometChatUI } from "../../cometchat-pro-react-ui-kit-master/CometChatWorkspace/src";
import CommonLoader from "../../components/Widgets/CommonLoader";
import {
  acceptMessageRequest,
  acceptVideoRequest,
  getChannels,
  unSubscribeChannel,
} from "../../utils/commonApis";
import { ApiCall } from "../../utils/api";
import { isEmpty } from "lodash";
import { toast } from "react-toastify";
import MyProfile from "../MyProfile/MyProfile";
import {
  generateHelmet,
  getComentChatUser,
  isRouteExistInMenu,
  vistaRedirectToBooth,
} from "../../utils/commonFunctions";
import { useStateIfMounted } from "use-state-if-mounted";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { setVideoConferenceData } from "../../redux/slice/videoConference";
import ProfileModal, { UserDataCard } from "../common/Modal/ProfileModal";
import { reloadChannelsData } from "../../redux/slice/headerData";

const Messages = (props) => {
  const location = useLocation();
  var CryptoJS = require("crypto-js");
  const [cometChatUser, setCometChatUser] = useStateIfMounted({
    isValidUser: false,
    data: {},
    uid: "",
  });
  const [subScribedChannels, setSubScribedChannels] = useStateIfMounted([]);
  const [currentChannelParentData, setCurrentChannelParentData] =
    useStateIfMounted({});
  const [currentChannelData, setCurrentChannelData] = useStateIfMounted({});
  const [loader, setLoader] = useStateIfMounted(false);
  const [userType, setUserType] = useStateIfMounted("");
  const [insideLoader, setInsideLoader] = useStateIfMounted(false);
  const [userProfileModal, setUserProfileModal] = useStateIfMounted({
    isOpen: false,
    otherUser: { user_id: "" },
    userData: null,
  });
  const [isActive, setActive] = useStateIfMounted({
    key: 0,
    show: true,
    channel_id: null,
    subscriber_id: null,
    chat_uid: null,
  });

  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ minWidth: 400 });
  const Tablet = useMediaQuery({ minWidth: 400, maxWidth: 1025 });
  const reloadChannelDataFlag = useSelector(
    (state) => state?.header?.reloadChannelDataFlag
  );

  console.log(reloadChannelDataFlag, "reloadChannelDataFlag");

  const history = useHistory();

  useEffect(async () => {
    let is_route_present = await isRouteExistInMenu(CryptoJS);
    if (is_route_present === false) {
      window.location.href = "/pagenotfound";
    } else {
      const ciphertext = localStorage.getItem("user_type");
      var bytes = CryptoJS.AES.decrypt(ciphertext, "user_type");
      var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      if (decryptedData === "SUPER ADMIN" || decryptedData === "CUSTOMER") {
        localStorage.setItem("event_name", "");
      }
      setUserType(decryptedData);
      const cometRes = await getComentChatUser();
      setCometChatUser(cometRes);

      if (
        location?.state?.isIndividual ||
        localStorage.getItem("isMessageScreenWithChatUID")
      ) {
        if (
          localStorage.getItem("isMessageScreenWithChatUID") &&
          localStorage.getItem("isMessageScreenWithChatUID") !== ""
        ) {
          setActive({
            key: 2,
            show: true,
            channel_id: null,
            subscriber_id: null,
            chat_uid: localStorage.getItem("isMessageScreenWithChatUID"),
          });
          setTimeout(() => {
            localStorage.removeItem("isMessageScreenWithChatUID");
          }, 2000);
        }
      }

      if (
        (decryptedData && decryptedData === "BRAND ADMIN") ||
        decryptedData === "AGENT"
      )
        getAllChannels();
    }
  }, [CryptoJS.AES]);

  useEffect(() => {
    if (reloadChannelDataFlag) {
      getAllChannels();
      fetchChannelDetailsAPI(isActive?.channel_id);
      setTimeout(() => {
        dispatch(
          reloadChannelsData({
            flag: false,
          })
        );
      }, 500);
    }
  }, [reloadChannelDataFlag]);
  console.log("Active", isActive);

  async function getAllChannels() {
    setLoader(true);
    setSubScribedChannels([]);
    const responseChannels = await getChannels();

    if (responseChannels) {
      setLoader(false);
      setSubScribedChannels(responseChannels);
    } else setLoader(false);
  }

  async function respondAPI(ele) {
    setInsideLoader(true);
    let response = await acceptMessageRequest({
      message_id: ele?.message_id,
      user_id: ele?.user_id,
      user_type: ele?.user_type,
      // message_text: ele.message_text,
    });

    if (response?.data?.code === 200) {
      toast.success(response.data.message);
      setActive({
        key: 2,
        show: true,
        channel_id: currentChannelParentData.channel_id,
        subscriber_id: currentChannelParentData.subscriber_id,
        chat_uid: null,
      });
      fetchChannelDetailsAPI(currentChannelParentData.channel_id);
      getAllChannels();
    } else {
      toast.error(response.data.message);
      setInsideLoader(false);
    }
  }

  async function fetchChannelDetailsAPI(channel_id) {
    setInsideLoader(true);
    const response = await ApiCall(
      "GET",
      "",
      `/admin/channel/detail/get?channel_id=${channel_id}`,
      ""
    );

    if (response?.data?.code === 200) {
      if (response?.data?.message) {
        setCurrentChannelData(response.data.data);
      }
      setInsideLoader(false);
    } else setInsideLoader(false);
  }

  async function acceptVideoAPI(ele) {
    setInsideLoader(true);
    let response = await acceptVideoRequest({
      call_request_id: ele.call_request_id,
      user_id: ele.user_id,
      video_call_link: ele.video_call_link,
    });

    if (response?.data?.code === 200) {
      toast.success(response.data.message);
      fetchChannelDetailsAPI(currentChannelParentData.channel_id);
      setTimeout(() => {
        dispatch(
          setVideoConferenceData({
            videoConferenceFlag: true,
            videoConferenceUrl: ele?.video_call_link,
            is_video_call: true,
          })
        );
      }, 2000);
      getAllChannels();
    } else {
      if (response?.data?.message) toast.error(response.data.message);
      setInsideLoader(false);
    }
  }

  const renderZoneDellReception = (currentChannelData) => {
    if (insideLoader) return <CommonLoader />;
    if (currentChannelData && currentChannelParentData)
      return (
        <>
          <div className="d-flex w-100 p-3 justify-content-between border-bottom-gray">
            <div className="w-25 messages_channel_name">
              <h5>{currentChannelParentData.channel}</h5>
            </div>
            <div className="d-flex w-50 justify-content-end channel_buttons_mobile">
              <Button
                onClick={async () => {
                  setLoader(true);

                  const res = await unSubscribeChannel({
                    subscriber_id: currentChannelParentData.subscriber_id,
                    enableSubscription: false,
                    channel_id: isActive?.channel_id,
                  });

                  if (
                    res?.code === 200
                    // res?.message === "Channel Subscription Updated Successfully"
                  ) {
                    toast.success(res?.message);
                    setCurrentChannelParentData(null);
                    setActive({
                      key: 1,
                      show: true,
                      channel_id: null,
                      subscriber_id: null,
                      chat_uid: null,
                    });
                  } else {
                    toast.error(res?.message);
                    setLoader(false);
                  }
                  await getAllChannels();
                }}
                className="messages_unsubscribeBtn d-flex justify-content-evenly align-items-center"
              >
                <CloseOutlined />
                Unsubscribe
              </Button>
              <Button
                onClick={() => {
                  let booth_details = currentChannelData?.booth_details;
                  let tier = booth_details?.brand_tier;
                  let zones = booth_details?.zones;
                  let sequence = null;
                  let brand_id = booth_details?.brand_id;

                  let eventID = null;
                  if (localStorage.getItem("event_id"))
                    eventID = localStorage.getItem("event_id");

                  let final_booth_url = booth_details?.final_booth_url;

                  vistaRedirectToBooth(
                    tier,
                    zones,
                    sequence,
                    userType,
                    brand_id,
                    eventID,
                    history,
                    dispatch,
                    final_booth_url
                  );
                }}
                className="messages_boothBtn ms-2 d-flex justify-content-evenly align-items-center"
              >
                <BoothRed />
                Visit Booth
              </Button>
            </div>
          </div>
          <div className="messages_content p-3 w-100">
            {currentChannelData &&
              currentChannelData.messageRequests &&
              currentChannelData.messageRequests.map((ele) => (
                <div
                  key={"message_request_" + ele.message_id}
                  className="messages_card mb-3 d-flex"
                >
                  <div className="w-50 bg-grey p-2 border rounded">
                    <h6 className="fs-pink">{ele.user_name}</h6>
                    <p style={{ wordBreak: "break-word" }}>
                      {ele.message_text}
                    </p>
                  </div>

                  <div className="my-auto ms-2">
                    <Button
                      onClick={async () => {
                        setLoader(true);

                        const response = await ApiCall(
                          "GET",
                          "",
                          "/admin/profilebooth/details/get",
                          {
                            chat_id: ele?.chat_id,
                          }
                        );

                        let user = response?.data?.data;

                        setUserProfileModal({
                          isOpen: true,
                          otherUser: { user_id: ele.user_id },
                          userData: {
                            bio: user?.bio,
                            company: user?.company,
                            country: user?.country,
                            email: user?.email,
                            event_domain_name: user?.event_domain_name,
                            image_url: user?.image_url,
                            is_calendar_access: user?.is_calendar_access,
                            job_title: user?.job_title,
                            tier_name: user?.tier_name,
                            user_name: user?.user_name,
                            user_type: user?.user_type,
                            chat_id: user?.chat_id,
                            user_id: user?.user_id,
                            users_users_social_media_handles_user_id:
                              user?.users_users_social_media_handles_user_id,
                          },
                        });
                        setLoader(false);
                      }}
                      className="messages_profileBtn d-flex justify-content-evenly align-items-center w-100"
                    >
                      <User />
                      <span style={{ paddingLeft: "5px" }}>Profile</span>
                    </Button>
                    <Button
                      onClick={() => respondAPI(ele)}
                      className="messages_respondBtn mt-2 d-flex justify-content-evenly align-items-center w-100"
                    >
                      <SendRed />
                      <span style={{ paddingLeft: "5px" }}>Respond</span>
                    </Button>
                  </div>
                </div>
              ))}

            {currentChannelData &&
              currentChannelData.videoRequests &&
              currentChannelData.videoRequests.map((ele) => (
                <div
                  key={"call_request_" + ele.call_request_id}
                  className="d-flex mt-3 w-100"
                >
                  <div className="d-flex p-2 border border-danger rounded w-50">
                    <div className="messages_warning_icon  rounded-circle  border border-danger p-1 d-flex justify-content-center align-items-center">
                      <ExclamationOutlined />
                    </div>
                    <span className="fs-pink ms-2">
                      {ele.user_name} has requested a video call.
                    </span>
                  </div>
                  <Button
                    className="videoBtn my-auto ms-2 d-flex justify-content-evenly align-items-center"
                    onClick={() => acceptVideoAPI(ele)}
                  >
                    <Video />
                    Accept
                  </Button>
                </div>
              ))}

            {!currentChannelData?.messageRequests &&
            !currentChannelData?.videoRequests ? (
              <Empty description="No requests found" />
            ) : null}
          </div>
        </>
      );
  };

  const resetUID = () => {
    setTimeout(() => {
      setActive({
        key: isActive.key,
        show: isActive.show,
        channel_id: isActive.channel_id,
        subscriber_id: isActive.subscriber_id,
        chat_uid: null,
      });
    }, 500);
  };

  const renderIndividualMessage = () => {
    return (
      <div id="cometchat" style={{ width: "100%", height: "100%" }}>
        {cometChatUser.isValidUser ? (
          <CometChatUI
            extraProps={{
              themeColor: "#c1a51e",
              userType: userType,
              dispatch: dispatch,
              history: history,
              chat_uid: isActive.chat_uid,
              resetUID: resetUID,
              socket: props.socket,
            }}
          />
        ) : null}
      </div>
    );
  };

  function clearProfileModal() {
    setUserProfileModal({ isOpen: false, otherUser: { user_id: "" } });
  }

  const eventName = localStorage.getItem("event_name");

  if (loader) return <CommonLoader />;
  else
    return (
      <div>
        <div className="d-flex justify-content-between align-items-center m-0 p-0">
          <div>
            <h4 className="fs-24 pt-3">Messages</h4>
          </div>
          {generateHelmet(
            eventName?.length > 0 ? eventName : "Gravit8",
            "My Calendar"
          )}
        </div>
        <div
          className={`${
            props.isFromJitsi
              ? "messages_jitsi_container"
              : "messages_container"
          }  ${isMobile && !Tablet ? "d-flex" : ""} w-100 mt-2`}
        >
          {userProfileModal.isOpen ? (
            <ProfileModal
              isProfileModalVisible={userProfileModal?.isOpen}
              isFromMessages={true}
              userData={userProfileModal?.userData}
              changeModalFlag={() => {
                setUserProfileModal({
                  isProfileModalVisible: false,
                });
              }}
            />
          ) : null}

          {userType === "BRAND ADMIN" || userType === "AGENT" ? (
            <>
              <div className="messages_sidebar">
                <div className="messages_search_wrapper mt-2 mx-auto">
                  <Input
                    size="medium"
                    className="w-100 rounded fs-bold-14"
                    allowClear
                    placeholder="Search Channels"
                    style={{ width: "70%" }}
                    prefix={<SearchOutlined />}
                  />
                </div>
                <div className="mt-4">
                  <h6
                    className={
                      isActive.key === 1
                        ? "activeBtn fs-pink mb-3 px-4 fs-18 py-3"
                        : "fs-pink mb-3 px-4 fs-18 py-3"
                    }
                  >
                    Zone Chats
                  </h6>
                  {subScribedChannels && !isEmpty(subScribedChannels) ? (
                    subScribedChannels.map((ele) => (
                      <div
                        key={"subchannels_" + ele.channel_id}
                        className={
                          isActive.key === 1 &&
                          isActive.show &&
                          ele.channel_id === isActive.channel_id &&
                          ele.subscriber_id === isActive.subscriber_id
                            ? "activeBtn messages_subscribe mb-2 d-flex justify-content-between px-4 py-2 messageSidebar"
                            : "messages_subscribe mb-2 d-flex justify-content-between px-4 py-2"
                        }
                        onClick={() => {
                          setActive({
                            key: 1,
                            show: true,
                            channel_id: ele?.channel_id,
                            subscriber_id: ele?.subscriber_id,
                            chat_uid: null,
                          });
                          setCurrentChannelParentData(ele);
                          fetchChannelDetailsAPI(ele.channel_id);
                          localStorage.removeItem("currentChatUser");
                        }}
                      >
                        <div
                          className={
                            isActive?.channel_id === ele?.channel_id
                              ? "messageSidebar"
                              : ""
                          }
                        >
                          <div className="d-flex">
                            <h6 className="opacity-75 fs-16">{ele.channel}</h6>
                            {ele.chat_request_count +
                              ele.video_request_count !==
                            0 ? (
                              <span className="subscribed_channels_count ms-2">
                                {ele.chat_request_count +
                                  ele.video_request_count}
                                {/* 2 */}
                              </span>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <Empty description="No Channels Found" />
                  )}
                </div>
                <div
                  className={
                    isActive.key === 2
                      ? "py-3 activeBtn messageSidebar"
                      : "py-3 messageSidebar"
                  }
                  onClick={() =>
                    setActive({
                      key: 2,
                      show: true,
                      channel_id: null,
                      subscriber_id: null,
                      chat_uid: null,
                    })
                  }
                >
                  <h6 className="fs-pink mb-3 px-4 fs-15">
                    Individual messages
                  </h6>
                </div>
              </div>
              <div className="w-100 h-100">
                {isActive.key === 1
                  ? renderZoneDellReception(currentChannelData)
                  : isActive.key === 2
                  ? renderIndividualMessage()
                  : null}
              </div>
            </>
          ) : userType === "SUPER ADMIN" ||
            userType === "CUSTOMER" ||
            userType === "DELEGATE" ||
            userType === "SPEAKER" ? (
            renderIndividualMessage()
          ) : null}
        </div>
      </div>
    );
};

export default Messages;
