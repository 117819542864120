import React, { useEffect, useState } from "react";
import VistaApp from "../Vista/src/VistaApp";
import { Layout, Modal, Button, Tooltip } from "antd";
import ReactPlayer from "react-player/lazy";
import "./WelcomePage.css";
import { HeartFilled, HeartOutlined } from "@ant-design/icons";
import { ApiCall } from "../../utils/api";
import { toast } from "react-toastify";

const WelcomePage = (props) => {
  const { Content } = Layout;

  const [likeCount, setLikeCount] = useState(0);
  const [isLiked, setIsLiked] = useState(false);
  const [mediaReactionDetails, setMediaReactionDetails] = useState(null);
  const [preEventUrl, setPreEventUrl] = useState("");
  const [welcomeUrl, setWelcomeUrl] = useState("");
  const [isPreEvent, setIsPreEvent] = useState(false);
  const [isImage, setIsImage] = useState(false);

  let skip_button = localStorage.getItem("skip_button");

  useEffect(() => {
    if (
      localStorage.getItem("pre_event_msg") ||
      localStorage.getItem("welcome_msg")
    ) {
      setPreEventUrl(localStorage.getItem("pre_event_msg"));
      setWelcomeUrl(localStorage.getItem("welcome_msg"));
    }

    const fileUrl =
      localStorage.getItem("pre_event_msg")?.trim()?.length > 0
        ? localStorage.getItem("pre_event_msg")
        : localStorage.getItem("welcome_msg");

    const validExtensions = ["jpg", "jpeg", "png", "gif", "webp"];
    const urlParts = fileUrl?.split(".");

    const fileExtension = urlParts[urlParts?.length - 1]?.toLowerCase();

    if (validExtensions?.includes(fileExtension)) {
      setIsImage(true);
    } else {
      setIsImage(false);
    }

    if (localStorage.getItem("isPreEvent")) {
      setIsPreEvent(JSON.parse(localStorage.getItem("isPreEvent")));
    }

    if (localStorage.getItem("mediaReactionDetails")) {
      let mediaReactionDetails = JSON.parse(
        localStorage.getItem("mediaReactionDetails")
      );
      setMediaReactionDetails(mediaReactionDetails);

      if (welcomeUrl.trim().length !== 0) {
        setIsLiked(mediaReactionDetails?.is_welcome_liked);
        setLikeCount(mediaReactionDetails?.welcome_like_count);
      } else {
        setIsLiked(mediaReactionDetails?.is_pre_event_liked);
        setLikeCount(mediaReactionDetails?.pre_event_like_count);
      }
    }
  }, []);

  const updateLikeCount = async () => {
    let body = {
      is_liked: !isLiked,
      media_type: welcomeUrl.trim().length !== 0 ? "WELCOME" : "PRE_EVENT",
    };
    setIsLiked(!isLiked);
    const response = await ApiCall(
      "POST",
      body,
      "/admin/events/media/reaction"
    );

    if (response.data.code === 200) {
      setLikeCount(response?.data?.data?.total_like_count);
    } else {
      toast.error(response.data.message);
    }
  };

  console.log("wwww isImage", isImage);

  return (
    <div
      className="welcome_popup"
      style={{ position: "fixed", maxHeight: "67vh" }}
    >
      <Modal
        className="welcome_modal"
        visible={true}
        width="96%"
        style={{
          position: "absolute",
          top: "5vh",
          width: "80%",
          left: "2%",
          textAlign: "right",
        }}
        closable={false}
        footer={[
          skip_button.trim() === "true" || isImage ? (
            <Button
              className="m-2"
              onClick={() => {
                if (isPreEvent) {
                  props?.history?.push("/mycalendar");
                } else {
                  props?.history?.push("/concourse");
                }
              }}
            >
              Skip
            </Button>
          ) : null,
        ]}
      >
        {mediaReactionDetails ? (
          <div className="video_welcome_like">
            <span>{likeCount}</span>
            {isLiked ? (
              <HeartFilled
                onClick={() => {
                  updateLikeCount();
                }}
                className="cursor-pointer pdf_brand_zone_like_red"
                style={{
                  fontSize: "18px",
                  marginRight: "40px",
                }}
              />
            ) : (
              <HeartOutlined
                onClick={() => {
                  updateLikeCount();
                }}
                className="cursor-pointer"
                style={{ fontSize: "18px", marginRight: "40px" }}
              />
            )}
          </div>
        ) : null}

        <span className="text-center welcome_message">
          <Tooltip
            placement="bottom"
            title={localStorage.getItem("welcome_title") ?? "NA"}
          >
            <p className="fs-18 fs-bold m-0 p-0 welcome_message_title">
              {localStorage.getItem("welcome_title") ?? "NA"}
            </p>
          </Tooltip>
          <Tooltip
            placement="bottom"
            title={localStorage.getItem("welcome_description" ?? "NA")}
          >
            <p className="fs-13 welcome_message_description my-2">
              {localStorage.getItem("welcome_description" ?? "NA")}
            </p>
          </Tooltip>
        </span>
        {!isImage ? (
          <ReactPlayer
            playing={true}
            controls={true}
            url={welcomeUrl?.trim()?.length !== 0 ? welcomeUrl : preEventUrl}
            className="react-player-class"
            width="100%"
            height="84%"
            onEnded={() => props?.history?.push("/concourse")}
          />
        ) : (
          <img
            src={welcomeUrl?.trim()?.length !== 0 ? welcomeUrl : preEventUrl}
            alt="Event media"
            className="welcome_image_container"
          />
        )}
      </Modal>
      <Layout>
        <Content
          className={
            props.isFromVista
              ? "vista_expomap_container"
              : "remove_scroll_vista"
          }
          style={{ backgroundColor: "#FBFBFB" }}
        >
          <VistaApp props={props} />
        </Content>
      </Layout>
    </div>
  );
};

export default WelcomePage;
