import React, { useState, useEffect } from "react";
import { Input } from "antd";
import "./SearchBar.css";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { vistaRedirectToBooth } from "../../utils/commonFunctions";
import { MenuUnfoldOutlined } from "@ant-design/icons";
import { setVideoConferenceData } from "../../redux/slice/videoConference";
import { toast } from "react-toastify";
import { onNetworkingJoinSessClickFlag } from "../../redux/slice/networking";

const SearchBar = (props) => {
  console.log("props?.details pppppp", props?.details);
  const [searchField, setSearchField] = useState("");
  const [userType, setUserType] = useState("");
  let CryptoJS = require("crypto-js");
  const history = useHistory();

  useEffect(() => {
    const ciphertext = localStorage.getItem("user_type");
    let bytes = CryptoJS.AES.decrypt(ciphertext, "user_type");
    let userType = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    setUserType(userType);
  }, [CryptoJS.AES]);

  const dispatch = useDispatch();
  const details = props.details;

  const filteredSearchData = details?.filter((data) => {
    return (
      data?.brand_title?.toLowerCase().includes(searchField?.toLowerCase()) ||
      data?.session_title?.toLowerCase().includes(searchField?.toLowerCase()) ||
      data?.event_name?.toLowerCase().includes(searchField?.toLowerCase())
    );
  });

  const handleChange = (e) => {
    setSearchField(e.target.value);
  };

  return (
    <div className="search_bar">
      {props.isMenu ? (
        <div className="w-100 brand-calender-search-div">
          <Input
            placeholder={props.placeholder}
            className={props.className}
            onChange={handleChange}
            allowClear
            style={{ width: "90%" }}
          />
          <MenuUnfoldOutlined
            className="w-10"
            onClick={() => props.onClickMenu()}
          />
        </div>
      ) : (
        <Input
          placeholder={props.placeholder}
          className={props.className}
          onChange={handleChange}
          allowClear
        />
      )}
      <div className="row mt-1 p-2">
        {searchField && searchField.length > 0 ? (
          <span className="text-secondary p-0 m-0 px-1">
            {filteredSearchData.length} Results
          </span>
        ) : null}
        {searchField && searchField.length > 0
          ? filteredSearchData?.map((data) => {
              return (
                <div
                  className="col-12 side_searchbar_div my-1"
                  style={{ backgroundColor: `${data.brand_color}` }}
                >
                  <p className="mt-1 mb-0 fs-14">
                    <b>{data?.session_title}</b>
                  </p>
                  <p className="m-0 fs-12">{data?.session_description}</p>
                  <div className="row mb-1">
                    <span
                      className="col-6 fs-12 border-end cursor-pointer"
                      onClick={() => {
                        if (data?.networking_configuration_id > 0) {
                          dispatch(
                            onNetworkingJoinSessClickFlag({
                              flag: true,
                              networking_session_id:
                                data?.networking_session_id,
                              networking_configuration_id:
                                data?.networking_configuration_id,
                            })
                          );
                          history.push("/networking");
                        } else {
                          let obj = {
                            videoConferenceFlag: true,
                            videoConferenceUrl: data?.join_session_url,
                            createPollFlag: data?.is_producer_curator,
                            isFromNetworking: false,
                            auditorium_session_id: data?.session_id,
                            isPollOptionsVisible: true,
                          };

                          dispatch(setVideoConferenceData(obj));
                        }
                      }}
                    >
                      <b>Go to Session</b>
                    </span>
                    <span
                      className="col-6 fs-12 cursor-pointer"
                      onClick={() => {
                        let tier =
                          data?.auditorium_hosting_brands?.[0]?.brand
                            ?.brand_tier ??
                          data?.networking_lounge_sponsoring_brands?.[0]?.brand
                            ?.brand_tier;
                        let zones =
                          data?.auditorium_hosting_brands?.[0]?.brand?.zones ??
                          data?.networking_lounge_sponsoring_brands?.[0]?.brand
                            ?.zones;
                        let sequence = null;
                        let brand_id =
                          data?.auditorium_hosting_brands?.[0]?.brand_id ??
                          data?.networking_lounge_sponsoring_brands?.[0]
                            ?.brand_id;
                        let eventID = data?.event_id ?? data?.event_id;
                        let final_booth_url =
                          data?.auditorium_hosting_brands?.[0]
                            ?.final_booth_url ??
                          data?.auditorium_hosting_brands?.[0]?.brand
                            ?.final_booth_url ??
                          data?.networking_lounge_sponsoring_brands?.[0]
                            ?.final_booth_url ??
                          data?.networking_lounge_sponsoring_brands?.[0]?.brand
                            ?.final_booth_url;

                        if (final_booth_url) {
                          vistaRedirectToBooth(
                            tier,
                            zones,
                            sequence,
                            userType,
                            brand_id,
                            eventID,
                            history,
                            dispatch,
                            final_booth_url
                          );
                        } else {
                          toast.error("Vista not uploaded");
                        }
                      }}
                    >
                      <b>Go to Booth</b>
                    </span>
                  </div>
                </div>
              );
            })
          : ""}
      </div>
    </div>
  );
};

export default SearchBar;
