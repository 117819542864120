import React, { useState } from "react";
import { Input } from "antd";
import Text from "antd/lib/typography/Text";
import { searchSessionsFromData } from "../eventCalender.utils";
import { MenuUnfoldOutlined, SearchOutlined } from "@ant-design/icons";
import { isEmpty } from "lodash";
import Sider from "antd/lib/layout/Sider";
import { vistaRedirectToBooth } from "../../../utils/commonFunctions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { setVideoConferenceData } from "../../../redux/slice/videoConference";
import { onNetworkingJoinSessClickFlag } from "../../../redux/slice/networking";

const EventCalenderSider = (props) => {
  const { allSessions, isCollapsedSider, setIsCollapsedSider, currentEventID } =
    props;

  console.log("currentEventId wwwww", currentEventID, allSessions);
  const [filteredSessions, setFilteredSessions] = useState([]);

  const dispatch = useDispatch();
  const history = useHistory();
  const userType = localStorage.getItem("userType");

  const searchSessions = (event) => {
    setFilteredSessions([]);
    let searchTerm = event.target.value.toLowerCase().trim();
    let allSearchedData = [];
    if (searchTerm !== "" || !isEmpty(searchTerm))
      allSearchedData = searchSessionsFromData(allSessions, searchTerm);
    if (searchTerm === "" || isEmpty(searchTerm)) setFilteredSessions([]);
    setFilteredSessions(allSearchedData);
  };

  console.log("ffiltered sessins wwwwww", filteredSessions);

  return (
    <Sider className="border-right bg-white" width={180}>
      <div className="p-3">
        <div className="d-flex flex-row-reverse" style={{ width: "100%" }}>
          <MenuUnfoldOutlined
            onClick={() => setIsCollapsedSider(!isCollapsedSider)}
          />
        </div>
        <Input
          size="medium"
          className="w-100 rounded fs-bold-14 mt-2"
          onChange={(e) => searchSessions(e)}
          allowClear
          placeholder="Search Session"
          style={{ width: "70%" }}
          prefix={<SearchOutlined />}
        />
        <div className="row mt-1 p-2">
          <span
            style={{ fontSize: "1rem" }}
            className="text-secondary p-0 m-0 px-1"
          >
            {filteredSessions.length} Results
          </span>
          {filteredSessions?.map((data, index) => {
            return (
              <div
                key={"filteredSessions_" + index.toString()}
                className="side_searchbar_div my-1 event_calender_searchbar"
              >
                <Text
                  strong
                  ellipsis
                  className="my-1"
                  style={{ fontSize: "0.8rem" }}
                >
                  {data?.session_title}
                </Text>
                <Text ellipsis className="mb-1" style={{ fontSize: "0.7rem" }}>
                  {data?.session_description}
                </Text>
                <div className="row mb-1">
                  <span
                    className="col-6 fs-pink border-end cursor-pointer"
                    style={{ fontSize: "0.5rem" }}
                    onClick={() => {
                      if (data?.networking_configuration_id > 0) {
                        dispatch(
                          onNetworkingJoinSessClickFlag({
                            flag: true,
                            networking_session_id: data?.networking_session_id,
                            networking_configuration_id:
                              data?.networking_configuration_id,
                          })
                        );
                        history.push("/networking");
                      } else {
                        let obj = {
                          videoConferenceFlag: true,
                          videoConferenceUrl: data?.join_session_url,
                          createPollFlag: data?.is_producer_curator,
                          isFromNetworking: false,
                          auditorium_session_id: data?.auditorium_session_id,
                          isPollOptionsVisible: true,
                        };
                        dispatch(setVideoConferenceData(obj));
                      }
                    }}
                  >
                    <b>Go to Session</b>
                  </span>
                  <span
                    className="col-6 fs-pink cursor-pointer"
                    style={{ fontSize: "0.5rem" }}
                    onClick={() => {
                      let tier =
                        data?.auditorium_hosting_brands?.[0]?.brand_tier ??
                        data?.networking_lounge_sponsoring_brands?.[0]
                          ?.brand_tier;
                      let zones =
                        data?.auditorium_hosting_brands?.[0]?.zones ??
                        data?.networking_lounge_sponsoring_brands?.[0]?.zones;
                      let sequence = null;
                      let brand_id =
                        data?.auditorium_hosting_brands?.[0]?.brand_id ??
                        data?.networking_lounge_sponsoring_brands?.[0]
                          ?.brand_id;
                      let eventID = currentEventID;
                      let final_booth_url =
                        data?.auditorium_hosting_brands?.[0]?.final_booth_url ??
                        data?.networking_lounge_sponsoring_brands?.[0]
                          ?.final_booth_url ??
                        data?.final_booth_url;
                      vistaRedirectToBooth(
                        tier,
                        zones,
                        sequence,
                        userType,
                        brand_id,
                        eventID,
                        history,
                        dispatch,
                        final_booth_url
                      );
                    }}
                  >
                    <b>Go to Booth</b>
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Sider>
  );
};

export default EventCalenderSider;
