import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.less";
import "./assets/css/custom.css";
import { BrowserRouter } from "react-router-dom";
import Loader from "./views/common/Loader";
import MainRoute from "./routes/index";
import { loginSlice } from "./redux/slice/login";
import { useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import io from "socket.io-client";
import { generateHelmet } from "./utils/commonFunctions";

const App = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  // const newSocket = io(process.env.REACT_APP_BASE_URL);
  // const newSocket = io("https://api.staging.gravit8.io");
  const newSocket = io(process.env.REACT_APP_SOCKET_URL);

  const [socket] = useState(newSocket);

  useEffect(() => {
    setLoading(false);
    // if (window.location.pathname === "/login") localStorage.clear();

    dispatch(loginSlice.actions.reAuthenticate());
    let obj = localStorage.getItem("btnColorData");
    let btnColorData = JSON.parse(obj);
    window.less
      .modifyVars({
        "@primary-color": localStorage.getItem("event_color_theme"),
        "@default_button_background_color":
          btnColorData?.default_button_background_color,
        "@default_button_background_second_color":
          btnColorData?.default_button_background_second_color,
        "@default_button_text_color": btnColorData?.default_button_text_color,
        "@highlight_button_background_color":
          btnColorData?.highlight_button_background_color,
        "@highlight_button_background_second_color":
          btnColorData?.highlight_button_background_second_color,
        "@highlight_button_text_color":
          btnColorData?.highlight_button_text_color,
        "@default_button_gradient_angle":
          btnColorData?.default_button_gradient_angle,
        "@highlight_button_gradient_angle":
          btnColorData?.highlight_button_gradient_angle,
      })
      .then(() => {});

    //io("https://api-grv8.dev.brainvire.net");
    // const newSocket = io("https://api.staging.gravit8.io");
    console.log("newSocket in app.js = ", newSocket);
    // setSocket(newSocket);

    return () => {
      newSocket.close();
    };
  }, []);

  return (
    <BrowserRouter>
      {loading ? <Loader /> : <MainRoute socket={socket} />}
      {generateHelmet("Gravit8 Admin", null)}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        theme="colored"
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </BrowserRouter>
  );
};

export default App;

// staging deployment test
