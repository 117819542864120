import React, { useState, useRef } from "react";
import {
  Tooltip,
  Button,
  Divider,
  Input,
  Popover,
  Col,
  Row,
  Modal,
} from "antd";
import Text from "antd/lib/typography/Text";
import { toast } from "react-toastify";
import {
  setExpoMapRedirectLinkName,
  setVistaBrandData,
  setVistaEventId,
} from "../../../redux/slice/vista";
import {
  updateExpoHallTitle,
  updateAudiTitle,
  updateNetworkTitle,
} from "../../../redux/slice/eventCalender";
import {
  checkIfChatbotTimeSlot,
  vistaRedirectToBooth,
} from "../../../utils/commonFunctions";
import {
  getChatbotJson,
  sendMessageAPI,
  updateAssetDownloadCount,
} from "../../../utils/commonApis";
import { isBoothLink, isVideoRoomLink } from "../eventCalender.utils";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Booth } from "../../../components/svgJS/Booth";
import { Chat } from "../../../components/svgJS/Chat";
import { Video } from "../../../components/svgJS/Video";
import { Download } from "../../../components/svgJS/Download";
import { User } from "../../../components/svgJS/User";
import { videoRequestAPI } from "../../../utils/commonApis";
import { getFileNameFromPath } from "../../../utils/commonFunctions";
import { isEmpty } from "lodash";
import SimpleReactValidator from "simple-react-validator";
import RedAsterick from "../../../components/Widgets/RedAsterick";
import { useDispatch } from "react-redux";
import { setVideoConferenceData } from "../../../redux/slice/videoConference";

const EventCalenderHeader = (props) => {
  const { TextArea } = Input;
  const messageValidator = useRef(new SimpleReactValidator());
  const [forceUpdate, setForceUpdate] = useState(false);
  const [messageModal, setMessageModal] = useState({ isOpen: false, data: {} });
  const [message_text, set_message_text] = useState("");
  const [popOverData, setPopOverData] = useState({
    titlePopVisible: false,
    parentTitle: "",
    updateTitle: "",
  });
  const [disableSendBtn, setDisableSendBtn] = useState(false);

  const {
    parentTitle,
    title,
    headerData,
    default_sponsor_details,
    userType,
    currentEventID,
    eventCalProps,
    isFromVista = false,
  } = props;
  console.log("headerData llll", headerData, default_sponsor_details);

  const dispatch = useDispatch();
  let normalLeftRightStyle = { justifyContent: "space-between" };
  if (parentTitle === "Main Auditorium")
    normalLeftRightStyle = { justifyContent: "center" };
  let filename = "",
    asset_name = "";

  if (
    (headerData &&
      !isEmpty(headerData) &&
      headerData.is_asset_enable &&
      headerData.is_asset_enable !== 0) ||
    (default_sponsor_details && default_sponsor_details.asset_file_url)
  ) {
    asset_name =
      !isEmpty(headerData) && headerData.asset_name
        ? headerData.asset_name
        : default_sponsor_details.asset_name;

    if (headerData && !isEmpty(headerData)) {
      if (headerData.is_asset_enable)
        filename = getFileNameFromPath(headerData.asset_file_url);
    } else {
      if (default_sponsor_details)
        filename = getFileNameFromPath(default_sponsor_details.asset_file_url);
    }
  }

  let logoURL =
    headerData && !isEmpty(headerData)
      ? headerData.brand_logo
      : default_sponsor_details
      ? default_sponsor_details.logo_url
      : "";

  let messageIcon = { isPresent: false, onClick: null };

  messageIcon = dataToCheckForChatbotAndMessage(
    headerData,
    default_sponsor_details
  );

  let isBoothLinkPresent = isBoothLink(headerData, default_sponsor_details);
  let isVideoRoomLinkPresent = isVideoRoomLink(
    headerData,
    default_sponsor_details
  );

  function dataToCheckForChatbotAndMessage(
    headerData,
    default_sponsor_details
  ) {
    let messageIcon = { isPresent: false, onClick: null };
    let chatbotResHeader = checkIfChatbotTimeSlot(headerData);
    let chatbotResDefault = checkIfChatbotTimeSlot(default_sponsor_details);

    if (chatbotResHeader && headerData.chat_bot_start_date_time) {
      messageIcon = {
        isPresent: true,
        onClick: async () => {
          let chat_bot_res = await getChatbotJson({
            zone_id: headerData.zone_id,
          });

          if (chat_bot_res && !isEmpty(chat_bot_res)) {
            props.setChatBotLocalJSON(chat_bot_res);
          } else toast.error("Chatbot details doesn't exist");
        },
      };
    } else if (
      headerData &&
      !isEmpty(headerData) &&
      headerData.is_chat_enabled &&
      headerData.is_chat_enabled !== 0 &&
      headerData.channel_id
    ) {
      messageIcon = {
        isPresent: true,
        onClick: () =>
          setMessageModal({
            isOpen: true,
            channel_id: headerData.channel_id,
          }),
      };
    } else if (
      ((default_sponsor_details && default_sponsor_details?.is_chat_enabled) ||
        (headerData && !isEmpty(headerData) && headerData?.is_chat_enabled)) &&
      chatbotResDefault &&
      default_sponsor_details?.chat_bot_start_date_time &&
      default_sponsor_details?.zone_id &&
      default_sponsor_details?.is_text_bot_enable
    ) {
      messageIcon = {
        isPresent: true,
        onClick: async () => {
          let chat_bot_res = await getChatbotJson({
            zone_id: default_sponsor_details?.zone_id,
          });

          if (chat_bot_res && !isEmpty(chat_bot_res)) {
            props.setChatBotLocalJSON(chat_bot_res);
          } else toast.error("Chatbot details doesn't exist");
        },
      };
    } else if (
      default_sponsor_details &&
      default_sponsor_details?.is_chat_enabled &&
      default_sponsor_details?.is_chat_enabled !== 0 &&
      default_sponsor_details?.channel_id
    ) {
      messageIcon = {
        isPresent: true,
        onClick: () =>
          setMessageModal({
            isOpen: true,
            channel_id: default_sponsor_details?.channel_id,
          }),
      };
    }
    return messageIcon;
  }

  const updateTitle = async (newTitle, props) => {
    let updateRes;
    if (props.parentTitle === "Expo Hall") {
      updateRes = await dispatch(
        updateExpoHallTitle({
          expo_hall_configuration_id: props.boxData.expo_hall_configuration_id,
          hall_title: newTitle,
        })
      );
    } else if (
      props.parentTitle === "Main Auditorium" ||
      props.parentTitle === "Satellite Auditorium"
    ) {
      updateRes = await dispatch(
        updateAudiTitle({
          auditorium_configuration_id:
            props.boxData.auditorium_configuration_id,
          auditorium_title: newTitle,
          auditorium_type: props.boxData.auditorium_type,
        })
      );
    } else if (props.parentTitle === "Networking Lounges") {
      updateRes = await dispatch(
        updateNetworkTitle({
          networking_configuration_id:
            props.boxData.networking_configuration_id,
          lounge_title: newTitle,
        })
      );
    }

    if (updateRes) {
      if (updateRes?.payload?.data?.code === 200) {
        toast.success("Title has been updated.");
        props.callFetchEventCalenderData();
      } else {
        if (updateRes.error && updateRes.error === "Network Error")
          toast.error("Please check the internet connection");
        if (updateRes.payload?.data?.message)
          toast.error("Something went wrong, please try again.");
      }
      setPopOverData({
        titlePopVisible: false,
        parentTitle: "",
        updateTitle: "",
      });
    }
  };

  function clearMessageAndModal() {
    set_message_text("");
    setMessageModal({ isOpen: false, data: {} });
  }

  async function callSendMessageAPI(channel_id) {
    if (messageValidator.current.allValid()) {
      setDisableSendBtn(true);
      if (props.cometChatUser.uid) {
        const response = await sendMessageAPI(channel_id, message_text);
        if (response.data.code === 200) {
          toast.success(response.data.message);
          clearMessageAndModal();
        } else toast.error(response.data.message);
      } else setForceUpdate(!forceUpdate);
    } else {
      messageValidator.current.showMessages();
      setForceUpdate(!forceUpdate);
    }
    setDisableSendBtn(false);
  }

  return (
    <div
      className="text-center"
      style={{ textAlign: "center", backgroundColor: "white" }}
    >
      {messageModal.isOpen ? (
        <Modal
          title={<b>Send Message</b>}
          visible={messageModal.isOpen}
          onOk={() => clearMessageAndModal()}
          onCancel={() => clearMessageAndModal()}
          footer={[
            <Button
              className="px-3 button-pink rounded"
              key="cancel"
              onClick={() => clearMessageAndModal()}
            >
              Cancel
            </Button>,
            <Button
              className="px-3 rounded"
              key="save"
              type="primary"
              onClick={() => callSendMessageAPI(messageModal?.channel_id)}
              disabled={disableSendBtn}
            >
              Send
            </Button>,
          ]}
          className="message_modal"
        >
          <Row className="ticker_configuration_row mt-2">
            <Col span={6} className="ticker_configuration_label">
              <p>
                Message
                <RedAsterick />
              </p>
            </Col>
            <Col span={12}>
              <TextArea
                name="message_text"
                id="message_text"
                className="ticker_configuration_inputDescription"
                placeholder="Enter a message"
                rows={4}
                value={message_text}
                onChange={(e) => set_message_text(e.target.value)}
                onBlur={() =>
                  messageValidator.current.showMessageFor("message_text")
                }
              />
              {messageValidator.current.message(
                "Message",
                message_text,
                "required",
                { className: "text-danger py-1" }
              )}
            </Col>
          </Row>
        </Modal>
      ) : null}
      <span className="hall_header_title fs-pink" style={{ fontSize: "1rem" }}>
        {parentTitle}
      </span>
      <div
        className="d-flex h-padding1"
        style={{
          paddingTop: "1%",
          height: `${props?.isFromVista ? "3rem" : "2rem"}`,
          ...normalLeftRightStyle,
        }}
      >
        {parentTitle !== "Main Auditorium" ? (
          <div style={{ width: "5%" }}>
            <LeftOutlined
              className="arrow_righ_left"
              onClick={() => props.onShuffleRightLeft(props, "left")}
            />
          </div>
        ) : null}

        <div className="box_title">
          <Text ellipsis style={{ fontSize: "0.9rem" }}>
            {title}
          </Text>
          <Popover
            placement="top"
            visible={
              popOverData.titlePopVisible &&
              popOverData.parentTitle === parentTitle
            }
            title={
              <div className="popover_header mt-2">
                <span style={{ fontSize: "1rem" }}>
                  {parentTitle + " Title"}
                </span>
              </div>
            }
            content={
              <div className="popover_bottom">
                <Input
                  minLength={1}
                  maxLength={15}
                  value={popOverData.updateTitle}
                  onChange={(e) =>
                    setPopOverData({
                      titlePopVisible: popOverData.titlePopVisible,
                      parentTitle: popOverData.parentTitle,
                      updateTitle: e.target.value,
                    })
                  }
                />
                <div>
                  <Button
                    className="mt-3 rounded"
                    onClick={() =>
                      setPopOverData({
                        titlePopVisible: false,
                        parentTitle: "",
                        updateTitle: "",
                      })
                    }
                  >
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    className="mt-3 mx-2 rounded"
                    onClick={() => updateTitle(popOverData.updateTitle, props)}
                  >
                    Update
                  </Button>
                </div>
              </div>
            }
          >
            {userType === "SUPER ADMIN" || userType === "CUSTOMER" ? (
              <div
                className="edit_icon ms-2"
                onClick={() =>
                  setPopOverData({
                    titlePopVisible: true,
                    parentTitle,
                    updateTitle: title,
                  })
                }
              />
            ) : null}
          </Popover>
        </div>
        {parentTitle !== "Main Auditorium" ? (
          <div style={{ width: "5%" }} className="mr-3">
            <RightOutlined
              className="arrow_righ_left"
              onClick={() => props.onShuffleRightLeft(props, "right")}
            />
          </div>
        ) : null}
      </div>
      <Divider style={{ margin: "2px 0" }} />

      <div style={{ height: "8rem" }}>
        <div className="mt-1 sponserd-by-div">
          <Text className="sponserd-by-text">Sponsored by</Text>
          <img className="box_logo" src={logoURL} />
        </div>
        <div
          id="box-icons"
          className="d-flex justify-content-around my-3 box_mini_icons"
        >
          {filename && !isEmpty(filename) ? (
            <Tooltip placement="right" title={asset_name} className="">
              <Button
                onClick={() => {
                  let canDownloadAsset =
                    headerData.is_asset_enable && headerData?.can_download_asset
                      ? headerData?.can_download_asset
                      : default_sponsor_details?.can_download_asset;

                  let assetUrl =
                    headerData?.is_asset_enable && headerData?.asset_file_url
                      ? headerData.asset_file_url
                      : default_sponsor_details.asset_file_url;

                  let zoneAssetId =
                    headerData?.is_asset_enable && headerData?.zone_asset_id
                      ? headerData?.zone_asset_id
                      : default_sponsor_details?.zone_asset_id;

                  if (canDownloadAsset) {
                    window.location.href = assetUrl;

                    updateAssetDownloadCount(zoneAssetId);
                  } else {
                    toast.error("Max Number of Downloads Reached");
                  }
                }}
                // href={
                //   headerData.is_asset_enable && headerData.asset_file_url
                //     ? headerData.asset_file_url
                //     : default_sponsor_details.asset_file_url
                // }
                download={filename}
                className="border p-1"
              >
                <Download />
              </Button>
            </Tooltip>
          ) : null}
          {isBoothLinkPresent ? (
            <Tooltip placement="right" title={"Visit Booth"} className="">
              <Button
                onClick={() => {
                  let tier =
                    headerData?.brand_tier ??
                    default_sponsor_details?.brand_tier;
                  let sequence = null;
                  let zones =
                    headerData?.brand?.zones ?? default_sponsor_details?.zones;
                  let brand_id =
                    headerData?.brand_id ?? default_sponsor_details?.brand_id;
                  let eventID = currentEventID;
                  let final_booth_url =
                    headerData?.final_booth_url ??
                    default_sponsor_details?.final_booth_url;

                  vistaRedirectToBooth(
                    tier,
                    zones,
                    sequence,
                    userType,
                    brand_id,
                    eventID,
                    eventCalProps.history,
                    dispatch,
                    final_booth_url
                  );
                }}
                className="border p-1"
              >
                <Booth />
              </Button>
            </Tooltip>
          ) : null}
          {messageIcon.isPresent ? (
            <Tooltip placement="right" title={"Chat"} className="">
              <Button
                className="border p-1"
                onClick={() => messageIcon.onClick()}
              >
                <Chat />
              </Button>
            </Tooltip>
          ) : null}
          {isVideoRoomLinkPresent ? (
            <Tooltip
              placement="right"
              title={"Join the demo room"}
              className=""
            >
              <Button
                onClick={() => {
                  dispatch(
                    setVideoConferenceData({
                      videoConferenceFlag: true,
                      videoConferenceUrl: headerData.zone_demo_room_link
                        ? headerData.zone_demo_room_link
                        : default_sponsor_details.zone_demo_room_link,
                      is_demo_room: true,
                    })
                  );
                }}
                className="border p-1"
              >
                <Video />
              </Button>
            </Tooltip>
          ) : null}
          {(headerData &&
            headerData.is_video_call_enable &&
            headerData.is_video_call_enable !== 0 &&
            headerData.channel_id) ||
          (default_sponsor_details &&
            default_sponsor_details.is_one_to_one_video_calls_enable &&
            default_sponsor_details.channel_id) ? (
            <Tooltip
              placement="right"
              title={"Next Available Agent"}
              className=""
            >
              <Button
                className="border p-1"
                onClick={async () => {
                  let channel_id =
                    headerData && headerData.channel_id
                      ? headerData.channel_id
                      : default_sponsor_details.channel_id;
                  const response = await videoRequestAPI(channel_id);
                  if (response.data.code === 200)
                    toast.success(response.data.message);
                  else toast.error(response.data.message);
                }}
              >
                <User />
              </Button>
            </Tooltip>
          ) : null}
        </div>
      </div>
      <Button
        className="rounded mt-3 goToButton"
        onClick={async () => {
          dispatch(
            setVistaBrandData({
              brand_id:
                headerData?.brand_id ?? default_sponsor_details?.brand_id,
            })
          );
          if (props?.eventCalProps?.onVistaPopUpClose) {
            props?.eventCalProps?.onVistaPopUpClose();
          }
          if (title) {
            let arr = title.split(" ");
            let linkName = "";
            let number = null;

            if (arr[0] !== "Lounge") {
              linkName = arr[0] + arr[1];
              number = parseInt(arr[2]);
            } else {
              linkName = arr[0];
              number = parseInt(arr[1]);
            }

            dispatch(
              setExpoMapRedirectLinkName({
                linkName,
                number,
                notFromExpoMap: true,
              })
            );
            if (userType === "SUPER ADMIN" || userType === "CUSTOMER") {
              dispatch(setVistaEventId(currentEventID));
            }
          } else if (parentTitle) {
            let linkName = parentTitle;
            let number = null;
            dispatch(setExpoMapRedirectLinkName({ linkName, number }));
            if (userType === "SUPER ADMIN" || userType === "CUSTOMER") {
              dispatch(setVistaEventId(currentEventID));
            }
          }
          if (isFromVista) {
            eventCalProps?.otherVistaProps?.history?.push("/expomap");
          } else {
            eventCalProps?.history?.push("/expomap");
          }
        }}
      >
        <Booth />
        <Text ellipsis className="go-to-title">
          {title ? "Go to " + title : "Go to " + parentTitle}
        </Text>
      </Button>
    </div>
  );
};

export default EventCalenderHeader;
