import React, { useEffect, useRef, useState } from "react";
import { Input, Avatar, Switch, Empty, Tooltip } from "antd";
import "./groupChat.css";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  SendOutlined,
} from "@ant-design/icons";
import { cloneDeep } from "lodash";
import { useSelector } from "react-redux";
import moment from "moment";
import { isEmpty } from "lodash";
import { createTZString, getCookie } from "../../utils/commonFunctions";
import { TIME_FORMAT } from "../../components/constsnts/common.constants";
import uuid from "react-uuid";
import { getAudiSession } from "../../utils/commonApis";
import { useLocation } from "react-router-dom";

var momentTz = require("moment-timezone");
var CryptoJS = require("crypto-js");

const GroupChat = (props) => {
  const socket = props.socket;
  const messagesSelector = useSelector((state) => state.groupChat);

  const [allMessages, setAllMessages] = useState([]);
  const [messageText, setMessageText] = useState();
  const [room] = useState("session_room_" + props.auditorium_session_id);
  const [auditorium_session_id] = useState(props.auditorium_session_id);
  const [is_moderator] = useState(props.is_moderator ?? false);
  const [loggedInUserId, setLoggedInUserId] = useState("");
  const [loggedInUserName, setLoggedInUserName] = useState("");
  const [sessionData, setSessionData] = useState(null);
  const location = useLocation();
  const sendMessageRef = useRef(null);
  const messagesEndRef = useRef(null);

  useEffect(async () => {
    const uuidHere = uuid();

    if (getCookie("user_id")) {
      const ciphertext = getCookie("user_id");
      const bytes = CryptoJS.AES.decrypt(ciphertext, "user_id");
      const id = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      setLoggedInUserId(id);
    } else {
      setLoggedInUserId(uuidHere);
    }

    if (getCookie("user_name")) {
      const ciphertextName = getCookie("user_name");
      const bytes2 = CryptoJS.AES.decrypt(ciphertextName, "user_name");
      const name = JSON.parse(bytes2.toString(CryptoJS.enc.Utf8));
      setLoggedInUserName(name);
    } else {
      setLoggedInUserName(uuidHere + "_user");
    }

    //called this API to check for moderators
    const query = new URLSearchParams(location.search);

    let sessionAPIData = await getAudiSession({
      auditorium_configuration_id:
        query.get("auditorium_configuration_id") ??
        props?.auditorium_configuration_id,
      auditorium_session_id:
        query.get("auditorium_session_id") ?? props?.auditorium_session_id,
    });

    setSessionData(sessionAPIData);

    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        // callMyFunction();

        sendMessageRef?.current?.click();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  useEffect(() => {
    if (messagesSelector && messagesSelector.allMessages)
      setAllMessages(messagesSelector.allMessages);
  }, [messagesSelector]);

  useEffect(() => {
    let createRoomRes = socket.emit(
      "createRoom",
      JSON.stringify({ room, auditorium_session_id })
    );

    const socketSingleMessageListener = (newGroupChatMessage) => {
      console.log(
        "newGroupChatMessage test33 = ",
        newGroupChatMessage,
        auditorium_session_id,
        room
      );
      console.log(
        "condition1 ",
        auditorium_session_id === newGroupChatMessage.auditorium_session_id
      );
      console.log("condition2 ", room === newGroupChatMessage.room);

      console.log(
        auditorium_session_id.toString(),
        newGroupChatMessage.auditorium_session_id.toString(),
        auditorium_session_id.toString() ===
          newGroupChatMessage.auditorium_session_id.toString(),
        room.toString(),
        newGroupChatMessage.room.toString(),
        room.toString() === newGroupChatMessage.room.toString()
      );

      if (
        newGroupChatMessage &&
        auditorium_session_id.toString() ===
          newGroupChatMessage.auditorium_session_id.toString() &&
        room.toString() === newGroupChatMessage.room.toString()
      ) {
        console.log("check if called");
        setAllMessages((previousData) => {
          let newMessages = cloneDeep(previousData);
          const onlyIdArr = previousData.map((ele) => ele.message_id);
          if (onlyIdArr.includes(newGroupChatMessage.message_id)) {
            const indexToChange = newMessages.findIndex(
              (ele) => ele.message_id === newGroupChatMessage.message_id
            );
            newMessages[indexToChange] = newGroupChatMessage;
          } else {
            let newObj = newGroupChatMessage;
            const new_date_time = createTZString(newObj?.time);

            newObj = {
              ...newObj,
              time: moment(new_date_time?.time).format("HH:mm"),
            };
            newMessages.push({
              ...newObj,
              // time: newObj?.time.format("HH:mm"),
            });
          }

          return newMessages;
        });
      }
      scrollToBottom();
    };

    const fetchHistoryListener = (historyData) => {
      console.log("all messages received = ", historyData);

      historyData.data = historyData?.data?.map((item) => {
        let new_time;
        if (item?.time) {
          new_time = createTZString(item?.time);

          new_time = moment(new_time.format("HH:mm"), "HH:mm");
        }

        return {
          ...item,
          time: moment(new_time).format("HH:mm"),
        };
      });

      if (
        auditorium_session_id.toString() ===
          historyData.auditorium_session_id.toString() &&
        room.toString() === historyData.room.toString()
      )
        setAllMessages(historyData.data);
      //scroll to botton
      scrollToBottom();
    };

    const createRoomListener = (res) => {
      console.log("res createRoomListener = ", res);
    };

    socket.emit(
      "fetchHistory",
      JSON.stringify({ auditorium_session_id, room })
    );

    socket?.on("newGroupChatMessage", socketSingleMessageListener);
    socket?.on("fetchHistory", fetchHistoryListener);
    socket?.on("createRoom", createRoomListener);

    return () => {
      socket?.off("newGroupChatMessage", socketSingleMessageListener);
      socket?.off("fetchHistory", fetchHistoryListener);
      socket?.off("createRoom", createRoomListener);
    };
  }, [socket]);

  const handleAddMessage = () => {
    let isAnyModeratorAvailable = false;
    if (sessionData?.message_board_details?.assigned_agent_ids?.length > 0) {
      isAnyModeratorAvailable = true;
    }

    let approvalFlag = false;
    if (isAnyModeratorAvailable) {
      if (is_moderator) {
        approvalFlag = true;
      }
    } else {
      if (
        sessionData?.message_board_details?.new_post_default_session_enabled
      ) {
        approvalFlag = true;
      } else {
        approvalFlag = false;
      }
    }

    let objToSend = {
      uuid: loggedInUserId,
      message: messageText,
      is_approved: approvalFlag,
      auditorium_session_id,
      name: loggedInUserName,
      time: momentTz.tz().toISOString(),
      room,
    };
    if (socket.connected) {
      let messageSocket = socket.emit(
        "newGroupChatMessage",
        JSON.stringify(objToSend)
      );

      console.log("messageSocket", messageSocket);

      setMessageText("");
    }
    scrollToBottom();
  };

  function approveDisapporve(item, val) {
    if (socket.connected) {
      let messageSocket = socket.emit(
        "approveGroupChatMessage",
        JSON.stringify({
          uuid: item.uuid,
          message: item.message,
          is_approved: val,
          is_disapproved: !val,
          name: item.name,
          time: moment().format("HH:mm"),
          message_id: item.message_id,
          auditorium_session_id,
          room,
        })
      );
    }
  }

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const renderOtherMessageUI = (item) => {
    if (item?.uuid.toString() === loggedInUserId.toString()) {
      return (
        <>
          <div className="d-flex flex-column">
            <div className="message_container right_sided_message_div">
              <div
                className={
                  item.is_approved
                    ? "message_container_self"
                    : "message_container_self_trans"
                }
              >
                {item.message}
              </div>
            </div>
            <div className="right_sided_message_div time_section">
              {item?.time}
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="message_container left_sided_message_div">
            {!item?.is_approved && !item?.is_disapproved && is_moderator ? (
              <>
                <div className="d-flex flex-column">
                  <div className="d-flex">
                    <div className="message_container_other">
                      <div className="d-flex">
                        <Avatar className="m-0 p-0" size="small">
                          {item?.name.substring(0, 1)}
                        </Avatar>
                        <b className="mx-2">{item.name}</b>
                      </div>
                      <div className="justify-content-between">
                        <span className="message_">{item.message}</span>
                      </div>
                    </div>

                    <div className="moderator_switch">
                      <Tooltip title="Apporve">
                        <CheckCircleOutlined
                          className="moderator_switch_svg_tick"
                          onClick={() => approveDisapporve(item, true)}
                        />
                      </Tooltip>
                      <Tooltip title="Reject">
                        <CloseCircleOutlined
                          className="moderator_switch_svg_cancel ms-1"
                          onClick={() => approveDisapporve(item, false)}
                        />
                      </Tooltip>
                    </div>
                  </div>

                  <div className="time_section mt-1">{item?.time}</div>
                </div>
              </>
            ) : null}
            {item?.is_approved ? (
              <>
                <div className="d-flex flex-column">
                  <div className="message_container_other">
                    <div className="d-flex">
                      <Avatar className="m-0 p-0" size="small">
                        {item?.name.substring(0, 1)}
                      </Avatar>
                      <b className="mx-2">{item.name}</b>
                    </div>
                    <div className="justify-content-between">
                      <span className="message_">{item.message}</span>
                    </div>
                  </div>
                  <div className="time_section mt-1">{item?.time}</div>
                </div>
              </>
            ) : null}
          </div>
        </>
      );
    }
  };

  return (
    <div className="main_chat_container">
      <div className="messages_area">
        {allMessages.map((item) => {
          return (
            <div key={"message_" + item.message_id}>
              {renderOtherMessageUI(item)}
            </div>
          );
        })}
        {/* Scroll at bottom of messages */}
        <div ref={messagesEndRef} />

        {/* {allMessages === [] || isEmpty(allMessages) ? (
          <div className="empty_chat">
            <Empty
              description={<span className="fs-pink">No messages found</span>}
            />
          </div>
        ) : null} */}
      </div>
      <div className="chat_bottom_section">
        <Input
          placeholder="Enter your message here"
          autoFocus
          value={messageText}
          onChange={(e) => {
            setMessageText(e.target.value);
          }}
          suffix={
            <span className="send_btn">
              <SendOutlined
                ref={sendMessageRef}
                className="iconnn"
                onClick={() => handleAddMessage()}
              />
            </span>
          }
        />
      </div>
    </div>
  );
};

export default GroupChat;
