import React, { useEffect, useState, useRef } from "react";
import { Breadcrumb, Select, Input, Button, Switch, Checkbox } from "antd";
import { addUser } from "../../redux/slice/userManagementSlice";
import { ApiCall } from "../../utils/api";
import { useDispatch, useSelector } from "react-redux";
import FileUpload from "../common/FileUpload";
import { toast } from "react-toastify";
import { Spin } from "antd";
import { LoadingOutlined, DeleteOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import "./index.css";
import SimpleReactValidator from "simple-react-validator";
import { getCountryList, getJobTitles, getZones } from "../../utils/commonApis";
import CommonLoader from "../../components/Widgets/CommonLoader";
import { cloneDeep } from "lodash";
import RedAsterick from "../../components/Widgets/RedAsterick";
import {
  INPUT_TEXT_LIMITS,
  TEXTAREA_TEXT_LIMITS,
} from "../../components/constsnts/common.constants";
import {
  isRouteExistInMenu,
  isValidHttpURL,
} from "../../utils/commonFunctions";
import { useMediaQuery } from "react-responsive";

const AddUser = (props) => {
  const { Option } = Select;
  const { TextArea } = Input;
  const dispatch = useDispatch();
  var CryptoJS = require("crypto-js");
  const { loading } = useSelector((state) => state?.userManagement);
  const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const validator = useRef(new SimpleReactValidator());
  const [forceUpdate, setForceUpdate] = useState(false);
  const [userType, setUserType] = useState("");
  const [loadingUser, setLoadingUser] = useState(false);
  const [eventList, setEventList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [tierList, setTierList] = useState([]);
  const [socialMediaList, setSocialMediaList] = useState([]);
  const [jobTitle, setJobTitle] = useState([]);
  const [profilePicture, setProfilePicture] = useState();
  const [zoneList, setZoneList] = useState([]);
  const [filteredZone, setFilteredZone] = useState([]);
  const [allowOtherCountry, setAllowOtherCountry] = useState(false);
  const [allowOtherJobTitle, setAllowOtherJobTitle] = useState(false);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedJobTitles, setSelectedJobTitles] = useState([]);
  const [brandValidation, setBrandValidation] = useState({
    name: "validation",
  });

  const initialState = {
    is_active: true,
    event_id: props?.event_id ?? null,
    first_name: "",
    last_name: "",
    email: "",
    new_email: "",
    company: "dummy",
    job_title_id: null,
    job_title: "",
    country_id: null,
    country: "",
    user_type: props?.event_id ? "AGENT" : null,
    tier_type_id: null,
    bio: "",
    is_admin: false,
    is_speaker: false,
    brand_id: null,
    website_url: "dummy.com",
    zone_tag_details: [],
    external_social_links_details: [],
  };
  const [fields, setFields] = useState(initialState);

  const Tablet = useMediaQuery({ maxWidth: 1200 });

  useEffect(async () => {
    setLoadingUser(true);
    const ciphertext = localStorage.getItem("user_type");
    var bytes = CryptoJS.AES.decrypt(ciphertext, "user_type");
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    setUserType(decryptedData);
    let is_route_present = await isRouteExistInMenu(CryptoJS);
    if (
      (is_route_present === false && decryptedData === "AGENT") ||
      decryptedData === "DELEGATE"
    ) {
      window.location.href = "/pagenotfound";
    } else {
      window.scrollTo(0, 0);
      getSocialMediaList();
      const countryListAPI = await getCountryList();
      setCountryList(countryListAPI);
      const jobTitlesListAPI = await getJobTitles();
      setJobTitle(jobTitlesListAPI);

      const zonesHere = await getZones();

      let finalZones = [];
      if (zonesHere) {
        zonesHere.forEach((ele) => {
          finalZones.push({
            is_assigned: false,
            is_always_get_messages: false,
            ...ele,
          });
        });

        setZoneList(finalZones);
      }

      //first set data from event listing
      let dataFromEventToSet = await getEventList();

      let clonedFields = cloneDeep(fields);
      let finalData = {};
      if (decryptedData === "BRAND ADMIN")
        finalData = { ...finalData, user_type: "AGENT" };

      if (dataFromEventToSet)
        finalData = { ...finalData, ...dataFromEventToSet };

      let eve_id = props?.event_id ? props?.event_id : finalData?.event_id;

      if (decryptedData === "BRAND ADMIN") {
        //second set data from tier listing
        let dataFromBrandToSet = await getBrandList(eve_id);
        if (dataFromBrandToSet)
          finalData = { ...finalData, ...dataFromBrandToSet };
      } else {
        let dataFromBrandToSet = await getBrandList(eve_id);
        if (dataFromBrandToSet)
          finalData = { ...finalData, ...dataFromBrandToSet };
      }

      //third set data from tier listing
      let brand_id_for_tiers = null;
      if (finalData?.brand_id) {
        if (decryptedData === "BRAND ADMIN") {
          brand_id_for_tiers = finalData?.brand_id;
        } else {
          if (brandList[0]?.brand_id) {
            brand_id_for_tiers = brandList[0]?.brand_id;
          } else {
            brand_id_for_tiers = finalData?.brand_id;
          }
        }

        let newZones = finalZones?.filter(
          (data) => data?.brand_id === brand_id_for_tiers
        );

        setFilteredZone(newZones);
      } else {
        if (props?.brand_id) {
          brand_id_for_tiers = props?.brand_id;

          let newZones = finalZones?.filter(
            (data) => data?.brand_id === brand_id_for_tiers
          );

          setFilteredZone(newZones);
        } else {
          brand_id_for_tiers = brandList[0]?.brand_id;
        }
      }

      let dataFromTierToSet = await getTierList(brand_id_for_tiers);
      if (dataFromTierToSet) finalData = { ...finalData, ...dataFromTierToSet };

      if (finalData) {
        clonedFields = { ...clonedFields, ...finalData };
      }

      getEventDetails(eve_id, countryListAPI, jobTitlesListAPI);

      if (props?.brand_id) {
        clonedFields.brand_id = props?.brand_id;
      }

      setFields(clonedFields);

      setTimeout(() => {
        setLoadingUser(false);
      }, 2000);
    }
  }, [CryptoJS.AES, props]);

  const getEventDetails = async (
    event_id,
    countryListAPI,
    jobTitlesListAPI
  ) => {
    const responseEvent = await ApiCall(
      "GET",
      "",
      `/admin/events/details/get`,
      { event_id }
    );

    if (responseEvent?.data?.data?.registration_and_communication_details) {
      let countryFlag =
        responseEvent?.data?.data?.registration_and_communication_details
          ?.country_collection;

      setAllowOtherCountry(
        responseEvent.data.data.registration_and_communication_details
          .allow_other_country
      );
      if (countryFlag && countryListAPI) {
        let countryIds =
          responseEvent?.data?.data?.registration_and_communication_details
            ?.country_collection_ids;
        let result = countryListAPI.filter((o1) =>
          countryIds.some((o2) => o1?.country_id === o2)
        );
        setSelectedCountries(result);
      }

      let jobFlag =
        responseEvent?.data?.data?.registration_and_communication_details
          ?.job_title_collection;
      setAllowOtherJobTitle(
        responseEvent.data.data.registration_and_communication_details
          .allow_other_job_title
      );

      if (jobFlag && jobTitlesListAPI) {
        let jobTitleIds =
          responseEvent?.data?.data?.registration_and_communication_details
            ?.job_title_collection_ids;
        let result2 = jobTitlesListAPI.filter((o1) =>
          jobTitleIds.some((o2) => o1?.job_title_id === o2)
        );
        setSelectedJobTitles(result2);
      }
    }
  };

  const clearFormState = () => {
    setFields(initialState);
    setProfilePicture(null);
    validator?.current?.hideMessages();
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const data =
      filteredZone &&
      filteredZone?.map((res) => {
        return {
          zone_id: res.zone_id,
          is_assigned: res.is_assigned,
          is_always_get_messages: res.is_always_get_messages,
        };
      });

    let formData = new FormData();
    formData.append(
      "data",
      JSON.stringify({ ...fields, zone_tag_details: data })
    );
    formData.append("profile_picture", profilePicture);

    let jobPresentCheck = selectedJobTitles?.some(
      (item) =>
        item?.job_title?.toLowerCase() === fields?.job_title?.toLowerCase()
    );

    let countryPresentCheck = selectedCountries?.some(
      (item) => item?.country?.toLowerCase() === fields?.country?.toLowerCase()
    );

    if (validator.current.allValid()) {
      let allURLValid = "yes";
      if (fields && fields.external_social_links_details) {
        let clonedLinks = cloneDeep(fields.external_social_links_details);
        fields.external_social_links_details.forEach((ele, index) => {
          if (
            ele.social_media_url === "" ||
            !isValidHttpURL(ele.social_media_url) ||
            ele.social_media_url === "https://www.instagram.com/" || //Cr Point -> User should not be able to save this if its just  https://www.instagram.com/ there should user id after the last "/".
            ele.social_media_url === "https://www.facebook.com/" ||
            ele.social_media_url === "https://twitter.com/" ||
            ele.social_media_url === "https://www.linkedin.com/"
          ) {
            clonedLinks[index]["urlValid"] = "no";
            allURLValid = "no";
          }
        });
        if (allURLValid === "no")
          setFields({ ...fields, external_social_links_details: clonedLinks });
      }

      if (allURLValid === "no") {
        toast.error("Please check social media links");
        return;
      }

      if (jobPresentCheck === true && fields?.job_title_id === "Other") {
        toast.error(
          "Job title is already exist, please select it from dropdown list"
        );
        return;
      }
      if (countryPresentCheck === true && fields?.country_id === "Other") {
        toast.error(
          "Country is already exist, please select it from dropdown list"
        );
        return;
      }

      setLoadingUser(true);
      const addNewUserResp = await dispatch(addUser(formData));
      if (addNewUserResp.payload.data.code === 200) {
        toast.success(addNewUserResp.payload.data.message);
        setLoadingUser(false);
        if (props?.event_id) {
          props?.onSuccessFromModal();
          clearFormState();
        } else {
          props?.history?.push("/usermanagement");
        }
      } else if (addNewUserResp.payload.data.code === 403) {
        toast.error(addNewUserResp.payload.data.message);
        setLoadingUser(false);
      } else toast.error(addNewUserResp.payload.data.message);
    } else {
      validator.current.showMessages();
      setLoadingUser(false);
      setForceUpdate(!forceUpdate);
      toast.error("Fields are missing");
    }
  };

  const handleFieldsChange = async (val, name) => {
    if (name === "event_id") {
      let dataFromBrandToSet = await getBrandList(
        props?.event_id ? props?.event_id : val
      );
      getEventDetails(
        props?.event_id ? props?.event_id : val,
        countryList,
        jobTitle
      );
      let finalData = cloneDeep(fields);
      if (dataFromBrandToSet)
        finalData = { ...finalData, ...dataFromBrandToSet };
      setFields({
        ...finalData,
        [name]: val,
        user_type: null,
        tier_type_id: null,
        brand_id: null,
        zone_tag_details: [],
      });
      setFilteredZone([]);
    } else if (name === "user_type") {
      let agentWiseData = {};
      let brand_id_per_user_type = null;
      if (val === "AGENT") {
        agentWiseData = { company: "dummy" };
        if (brandList?.length !== 1) {
          setBrandValidation(null);
        }

        brand_id_per_user_type = brandList?.[0].brand_id;
        if (brandList?.length === 1) {
          let newZones = zoneList?.filter(
            (data) => data?.brand_id === brandList?.[0].brand_id
          );
          setFilteredZone(newZones);
        }
      } else if (val === "DELEGATE") {
        brand_id_per_user_type = null;
        agentWiseData = { brand_id: null, tier_type_id: null, company: "" };
        setBrandValidation({ dummyValue: "22" });
      } else if (val === "SPEAKER") {
        brand_id_per_user_type = null;
        agentWiseData = {
          brand_id: null,
          tier_type_id: null,
          company: "",
        };

        setBrandValidation({ dummyValue: "33" });
      }
      let dataToSetFromTier = await getTierList(brand_id_per_user_type);

      let finalData = cloneDeep(fields);
      let dataFromBrandToSet = await getBrandList(
        props?.event_id ? props?.event_id : finalData?.event_id
      );
      if (dataFromBrandToSet)
        finalData = { ...finalData, ...dataFromBrandToSet };
      if (dataToSetFromTier)
        setFields({
          ...finalData,
          [name]: val,
          ...dataToSetFromTier,
          ...agentWiseData,
        });
      else setFields({ ...finalData, [name]: val, ...agentWiseData });
    } else if (name === "brand_id") {
      if (val) {
        let newZones = zoneList?.filter((data) => data?.brand_id === val);
        setFilteredZone(newZones);
      }

      let dataToSetFromTier = await getTierList(val);
      if (dataToSetFromTier)
        setFields({ ...fields, [name]: val, ...dataToSetFromTier });
      else setFields({ ...fields, [name]: val });
      setBrandValidation({ dummyValue: "44" });
    } else setFields({ ...fields, [name]: val });
  };

  const onHandleSocialSwitchChange = (checked, idx) => {
    let social_media_list = socialMediaList;
    let external_social_links_details = fields.external_social_links_details;
    if (checked) {
      external_social_links_details.push({
        social_media_id: social_media_list[idx].social_media_id,
        social_media_url:
          idx === 0
            ? "https://www.instagram.com/" //CR point -> To add prefix when toggle is on
            : idx === 1
            ? "https://www.linkedin.com/"
            : idx === 2
            ? "https://twitter.com/"
            : idx === 3
            ? "https://www.facebook.com/"
            : "",
        checked,
      });
    } else {
      let index = external_social_links_details.findIndex(
        (social_media) =>
          social_media.social_media_id ===
          social_media_list[idx]["social_media_id"]
      );
      external_social_links_details.splice(index, 1);
    }
    setFields({ ...fields, external_social_links_details });
  };

  const socialMediaHandleURLChange = (idx, urlValue) => {
    let social_media_list = socialMediaList;
    let external_social_links_details = cloneDeep(
      fields.external_social_links_details
    );
    let isAvailable = external_social_links_details.some(
      (social_media) =>
        social_media.social_media_id ===
        social_media_list[idx]["social_media_id"]
    );
    if (isAvailable) {
      let index = external_social_links_details.findIndex(
        (social_media) =>
          social_media.social_media_id ===
          social_media_list[idx]["social_media_id"]
      );
      external_social_links_details[index]["social_media_url"] = urlValue;
      external_social_links_details[index]["urlValid"] = "yes";
    } else {
      external_social_links_details.push({
        social_media_id: social_media_list[idx].social_media_id,
        social_media_url: urlValue,
        urlValid: "yes",
      });
    }
    setFields({ ...fields, external_social_links_details });
  };

  const handleImgChange = (event) => {
    setProfilePicture(event.target.files[0]);
  };

  async function getEventList() {
    return await ApiCall("get", null, "/admin/events/dropdown/get", null)
      .then((response) => {
        const { rows } = response?.data?.data;
        setEventList(rows);
        let dataToPass = {};
        if (rows.length === 1) {
          dataToPass["event_id"] = rows[0]?.event_id;
        }
        return dataToPass;
      })
      .catch((err) => {
        console.error(err);
        return {};
      });
  }

  async function getBrandList(event_id) {
    return await ApiCall("get", null, "/admin/brand/dropdown/get", {
      event_id: event_id ? event_id : "",
    })
      .then((response) => {
        const { rows } = response?.data?.data;
        setBrandList(rows);
        let dataToPass = {};
        if (rows.length === 1) {
          dataToPass["brand_id"] = rows[0]?.brand_id;
          setBrandValidation({ name: "validation" });
        }

        //keep this code for few days
        // if (rows.length !== 1 && fields?.user_type === "AGENT") {

        //   setBrandValidation(null);
        // }
        if (userType === "BRAND ADMIN") dataToPass["user_type"] = "AGENT";
        return dataToPass;
      })
      .catch((err) => {
        return {};
      });
  }

  async function getTierList(brand_id) {
    return await ApiCall("get", null, "/admin/tiers/get", {
      brand_id,
    })
      .then((response) => {
        const { data } = response?.data;
        if (data.length === 1) {
          setTierList(data);
          return { tier_type_id: data[0]?.tier_id };
        } else {
          setTierList(data);
          return {};
        }
      })
      .catch((err) => {
        return {};
      });
  }

  const getSocialMediaList = () => {
    ApiCall("get", null, "/admin/socialmediatype/get", null)
      .then((response) => {
        setSocialMediaList(response?.data?.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleFileDelete = () => {
    setProfilePicture(null);
  };

  const handleCheckBox1Change = (e, idx) => {
    let filtered_zone = filteredZone;
    filtered_zone[idx].is_assigned = e.target.checked;
    setFilteredZone([...filtered_zone]);
  };

  const handleCheckBox2Change = (e, idx) => {
    let filtered_zone = filteredZone;
    filtered_zone[idx].is_always_get_messages = e.target.checked;
    setFilteredZone([...filtered_zone]);
  };

  if (loadingUser) return <CommonLoader />;
  else
    return (
      <form
        // onSubmit={handleFormSubmit}
        autoComplete="off"
      >
        <div>
          {props?.event_id ? (
            ""
          ) : (
            <>
              <div className="position-fixed d-flex justify-content-between bg-light w-100 common-sticky-header">
                <div>
                  <h4 className="fs-24">Add User</h4>
                  <Breadcrumb className="my-2" separator=">">
                    <Breadcrumb.Item>
                      <Link to="/usermanagement">Admin Console</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link to="/usermanagement">User Management</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link to="/adduser">Add User</Link>
                    </Breadcrumb.Item>
                  </Breadcrumb>
                </div>
              </div>
            </>
          )}
          <div
            className={
              props?.event_id ? "bg-white px-5 py-1" : "bg-white px-5 py-3 mt-5"
            }
          >
            <div className={props?.event_id ? "d-none" : "row my-3"}>
              <div className="col-12 mt-4">
                <h6 className="font-pink fw-bolder fs-20">User Editor</h6>
              </div>
            </div>
            <div className={props?.event_id ? "row my-1" : "row my-3"}>
              <div className="col-3 text-end">Active</div>
              <div className="col-1">
                <Switch
                  name="is_active"
                  checked={fields.is_active}
                  onChange={(checked) =>
                    handleFieldsChange(checked, "is_active")
                  }
                />
              </div>
            </div>
            <div className="row my-3 mt-4">
              <div className="col-3 text-end">
                Select Event
                <RedAsterick />
              </div>

              <div className="col-9">
                <Select
                  showSearch
                  className={
                    validator?.current?.fields?.event === false &&
                    validator?.current?.messagesShown === true
                      ? "w-25 fs-bold-14 border_red"
                      : Tablet
                      ? "w-75 fs-bold-14 "
                      : "w-25"
                  }
                  placeholder="Select Event"
                  optionFilterProp="children"
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                  name="event_id"
                  value={fields.event_id ? fields.event_id : null}
                  onChange={(val) => handleFieldsChange(val, "event_id")}
                  onBlur={() => validator.current.showMessageFor("event_id")}
                  autoComplete="none"
                  listHeight={120}
                  listItemHeight={4}
                  disabled={
                    userType === "BRAND ADMIN"
                      ? true
                      : props?.event_id
                      ? true
                      : false
                  }
                >
                  {eventList &&
                    eventList?.map((data) => {
                      return (
                        <Option value={data.event_id}>{data.event_name}</Option>
                      );
                    })}
                </Select>
                {validator.current.message(
                  "event",
                  fields.event_id,
                  "required|text",
                  { className: "text-danger py-1" }
                )}
              </div>
            </div>
            <div className="row my-3">
              <div className="col-3 text-end">
                First Name
                <RedAsterick />
              </div>
              <div className="col-9">
                <Input
                  name="first_name"
                  id="first_name"
                  className={
                    validator?.current?.fields?.FirstName === false &&
                    validator?.current?.messagesShown === true
                      ? "w-25 fs-bold-14 border_red"
                      : Tablet
                      ? "w-75 fs-bold-14 "
                      : "w-25 fs-bold-14"
                  }
                  placeholder="Enter First Name"
                  value={fields.first_name}
                  onChange={(e) =>
                    handleFieldsChange(e.target.value, "first_name")
                  }
                  onBlur={() => validator.current.showMessageFor("first_name")}
                  autoComplete="none"
                  maxLength={INPUT_TEXT_LIMITS.MAX}
                />
                {validator.current.message(
                  "FirstName",
                  fields.first_name,
                  "required|first_name",
                  { className: "text-danger py-1" }
                )}
              </div>
            </div>
            <div className="row my-3">
              <div className="col-3 text-end">
                Surname
                <RedAsterick />
              </div>
              <div className="col-9">
                <Input
                  name="last_name"
                  id="last_name"
                  className={
                    validator?.current?.fields?.LastName === false &&
                    validator?.current?.messagesShown === true
                      ? "w-25 fs-bold-14 border_red"
                      : Tablet
                      ? "w-75 fs-bold-14 "
                      : "w-25 fs-bold-14"
                  }
                  placeholder="Enter Last Name"
                  value={fields.last_name}
                  onChange={(e) =>
                    handleFieldsChange(e.target.value, "last_name")
                  }
                  onBlur={() => validator.current.showMessageFor("last_name")}
                  autoComplete="none"
                  maxLength={INPUT_TEXT_LIMITS.MAX}
                />
                {validator.current.message(
                  "LastName",
                  fields.last_name,
                  "required|text",
                  { className: "text-danger py-1" }
                )}
              </div>
            </div>
            <div className="row my-3">
              <div className="col-3 text-end">
                Email Address
                <RedAsterick />
              </div>
              <div className="col-9">
                <Input
                  name="email"
                  id="email"
                  className={
                    validator?.current?.fields?.email === false &&
                    validator?.current?.messagesShown === true
                      ? "w-25 fs-bold-14 border_red"
                      : Tablet
                      ? "w-75 fs-bold-14 "
                      : "w-25 fs-bold-14"
                  }
                  placeholder="Enter Email"
                  value={fields.email}
                  onChange={(e) => handleFieldsChange(e.target.value, "email")}
                  onBlur={() => validator.current.showMessageFor("email")}
                  autoComplete="none"
                  maxLength={INPUT_TEXT_LIMITS.MAX}
                />
                {validator.current.message(
                  "email",
                  fields.email,
                  "required|email",
                  { className: "text-danger py-2" }
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-3 text-end">Profile Picture</div>
              <div className="col-9 d-flex prof_user_btn">
                <FileUpload
                  labelClassName="rounded border-pink btn-svg  "
                  labelTitle="Image Upload"
                  name="profilePicture"
                  inputType="file"
                  inputOnChange={handleImgChange}
                  value=""
                  inputId="add_user_logo"
                  inputName="add_user_logo"
                />
                {profilePicture ? (
                  <>
                    <span className="mx-3"> {profilePicture.name} </span>
                    <span onClick={handleFileDelete}>
                      <DeleteOutlined />
                    </span>
                  </>
                ) : null}
                <br />
                <br />
              </div>
            </div>
            <div className="row my-0 py-0">
              <div className="col-3"></div>
              <div className="col-9"></div>
            </div>

            <div className="row my-3">
              <div className="col-3 text-end">
                Job Role
                {/* <RedAsterick /> */}
              </div>
              <div className="col-9">
                <Select
                  showSearch
                  className={
                    validator?.current?.fields?.Job === false &&
                    validator?.current?.messagesShown === true
                      ? "w-25 fs-bold-14 border_red"
                      : Tablet
                      ? "w-75 fs-bold-14 "
                      : "w-25 fs-bold-14"
                  }
                  placeholder="Select Job Role"
                  optionFilterProp="children"
                  name="job_title_id"
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                  value={fields?.job_title_id}
                  onChange={(val) => handleFieldsChange(val, "job_title_id")}
                  // onBlur={() =>
                  //   validator.current.showMessageFor("job_title_id")
                  // }
                  // autoComplete="off"
                  autoComplete="none"
                  listHeight={120}
                  listItemHeight={4}
                >
                  {selectedJobTitles &&
                    selectedJobTitles?.map((data) => {
                      return (
                        <Option value={data.job_title_id}>
                          {data.job_title}
                        </Option>
                      );
                    })}
                  {allowOtherJobTitle && <Option value="Other">Other</Option>}
                </Select>
                {/* {validator.current.message(
                  "Job",
                  fields?.job_title_id,
                  "required|text",
                  { className: "text-danger py-1" }
                )} */}
              </div>
              <div className="col-3"></div>
              {fields?.job_title_id === "Other" && (
                <div className="col-9 mt-2">
                  <Input
                    name="job_title"
                    id="job_title"
                    className={`fs-bold-14 ${Tablet ? "w-75" : "w-25"}`}
                    placeholder="Enter job_title "
                    value={fields?.job_title}
                    maxLength={INPUT_TEXT_LIMITS.MAX}
                    onChange={(e) =>
                      handleFieldsChange(e.target.value, "job_title")
                    }
                    autoComplete="none"
                  />
                </div>
              )}
            </div>

            <div className="row my-3">
              <div className="col-3 text-end">
                Country
                {/* <RedAsterick /> */}
              </div>
              <div className="col-9">
                <Select
                  showSearch
                  className={
                    validator?.current?.fields?.country === false &&
                    validator?.current?.messagesShown === true
                      ? "w-25 fs-bold-14 border_red"
                      : Tablet
                      ? "w-75 fs-bold-14 "
                      : "w-25 fs-bold-14"
                  }
                  placeholder="Select Country"
                  optionFilterProp="children"
                  name="country_id"
                  value={fields.country_id}
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                  onChange={(val) => handleFieldsChange(val, "country_id")}
                  // onBlur={() => validator.current.showMessageFor("country_id")}
                  autoComplete="none"
                  listHeight={120}
                  listItemHeight={4}
                >
                  {selectedCountries &&
                    selectedCountries?.map((data) => {
                      return (
                        <Option value={data?.country_id}>
                          {data?.country}
                        </Option>
                      );
                    })}
                  {allowOtherCountry && <Option value="Other">Other</Option>}
                </Select>
                {/* {validator.current.message(
                  "country",
                  fields.country_id,
                  "required|text",
                  { className: "text-danger py-1" }
                )} */}
              </div>
              <div className="col-3"></div>
              {fields?.country_id === "Other" && (
                <div className="col-9 mt-2">
                  <Input
                    name="country"
                    id="country"
                    maxLength={INPUT_TEXT_LIMITS.MAX}
                    className={`fs-bold-14 ${Tablet ? "w-75" : "w-25"}`}
                    placeholder="Enter Country Name"
                    value={fields.country}
                    onChange={(e) =>
                      handleFieldsChange(e.target.value, "country")
                    }
                    autoComplete="none"
                  />
                </div>
              )}
            </div>

            <div className="row mt-3">
              <div className="col-3 text-end">
                User Type
                <RedAsterick />
              </div>

              <div className="col-9">
                <Select
                  showSearch
                  className={
                    validator?.current?.fields?.UserType === false &&
                    validator?.current?.messagesShown === true
                      ? "w-25 fs-bold-14 border_red"
                      : Tablet
                      ? "w-75 fs-bold-14 "
                      : "w-25 fs-bold-14"
                  }
                  placeholder="Select User"
                  optionFilterProp="children"
                  name="user_type"
                  value={fields.user_type}
                  onChange={(val) => handleFieldsChange(val, "user_type")}
                  autoComplete="none"
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                  disabled={
                    userType === "BRAND ADMIN"
                      ? true
                      : props?.event_id
                      ? true
                      : false
                  }
                >
                  <Option value="AGENT">Agent</Option>
                  <Option
                    value="DELEGATE"
                    className={userType === "BRAND ADMIN" ? "d-none" : ""}
                  >
                    Delegate
                  </Option>
                  <Option
                    value="SPEAKER"
                    className={userType === "BRAND ADMIN" ? "d-none" : ""}
                  >
                    Speaker
                  </Option>
                </Select>
                {validator.current.message(
                  "UserType",
                  fields.user_type,
                  "required|text",
                  { className: "text-danger py-1" }
                )}
              </div>
            </div>
            {fields.user_type === "AGENT" && (
              <>
                {!props?.navigate_fromZone && (
                  <div className="row my-3">
                    <div className="col-3 text-end">Admin</div>
                    <div className="col-7">
                      <Switch
                        name="is_admin"
                        checked={fields.is_admin}
                        onChange={(checked) =>
                          handleFieldsChange(checked, "is_admin")
                        }
                      />
                    </div>
                  </div>
                )}
              </>
            )}

            {fields.user_type === "AGENT" && (
              <>
                <div className={fields.user_type ? "row mt-3" : "d-none"}>
                  <div className="col-3 text-end">
                    Brand
                    <RedAsterick />
                  </div>
                  {userType && userType === "BRAND ADMIN" ? (
                    <div className="col-9">
                      <Input
                        className={`fs-bold-14 ${Tablet ? "w-75" : "w-25"}`}
                        value={brandList[0]?.brand_title}
                        disabled
                      />
                    </div>
                  ) : (
                    <div className="col-9">
                      <Select
                        showSearch
                        className={
                          validator?.current?.fields?.brand === false &&
                          validator?.current?.messagesShown === true
                            ? "w-25 fs-bold-14 border_red"
                            : Tablet
                            ? "w-75 fs-bold-14 "
                            : "w-25 fs-bold-14"
                        }
                        placeholder="Select Brand"
                        optionFilterProp="children"
                        getPopupContainer={(triggerNode) => {
                          return triggerNode.parentNode;
                        }}
                        name="brand_id"
                        value={fields.brand_id}
                        onChange={(val) => handleFieldsChange(val, "brand_id")}
                        // onBlur={() =>
                        //   validator.current.showMessageFor("brandValidation")
                        // }
                        autoComplete="none"
                        listHeight={120}
                        listItemHeight={4}
                        disabled={
                          userType && userType === "BRAND ADMIN"
                            ? true
                            : props?.brand_id
                            ? true
                            : false
                        }
                      >
                        {brandList &&
                          brandList?.map((data) => {
                            return (
                              <Option value={data.brand_id}>
                                {data.brand_title}
                              </Option>
                            );
                          })}
                      </Select>
                    </div>
                  )}
                </div>
              </>
            )}

            <div className="row">
              <div className="col-3"></div>
              <div className="col-9">
                {validator.current.message(
                  "brand",
                  brandValidation,
                  "required",
                  {
                    className: "text-danger py-1",
                  }
                )}
                {brandList && brandList.length === 0 ? (
                  <div
                    className={
                      brandList && brandList.length === 0
                        ? " d-none"
                        : "d-block mt-2 fs-14 text-danger"
                    }
                  >
                    <span>
                      No brands is available for above selected "Event"
                    </span>
                  </div>
                ) : null}
              </div>
            </div>
            {/* )} */}

            {fields.user_type === "AGENT" && (
              <>
                <div
                  className={
                    fields?.user_type === "AGENT" ? "row my-3" : "d-none"
                  }
                >
                  <div className="col-3 text-end">Zone Tags</div>
                  <div className="col-8 border-bottom zone-tag-scroll-div">
                    <div className="row border border-gray rounded py-1 sticky-top bg-white">
                      <div className="col-4">
                        <b>Zones</b>
                      </div>
                      <div className="col-4">
                        <b>Assign</b>
                      </div>
                      <div className="col-4">
                        <b>Always get Msgs</b>
                      </div>
                    </div>

                    {filteredZone &&
                      filteredZone?.map((data, idx) => (
                        <div className="row border-start border-end">
                          <div className="col-4">
                            <div className="my-3">{data.zone_title}</div>
                          </div>
                          <div className="col-4 text-start pt-3">
                            <Checkbox
                              className="mx-3"
                              name="assign"
                              onChange={(e) => handleCheckBox1Change(e, idx)}
                            />
                          </div>
                          <div className="col-4 text-start pt-3">
                            <Checkbox
                              disabled={
                                filteredZone[idx].is_assigned === true
                                  ? false
                                  : true
                              }
                              className="mx-3"
                              name="alwaysGetMags"
                              onChange={(e) => handleCheckBox2Change(e, idx)}
                            />
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </>
            )}

            {fields?.user_type === "DELEGATE" ||
            fields?.user_type === "SPEAKER" ? (
              <div className="row mt-3">
                <div className="col-3 text-end">
                  Company
                  <RedAsterick />
                </div>
                <div className="col-9">
                  <Input
                    name="company"
                    id="company"
                    maxLength={INPUT_TEXT_LIMITS.MAX}
                    className={
                      validator?.current?.fields?.Company === false &&
                      validator?.current?.messagesShown === true
                        ? "w-25 fs-bold-14 border_red"
                        : Tablet
                        ? "w-75 fs-bold-14 "
                        : "w-25 fs-bold-14"
                    }
                    placeholder="Enter Company Name"
                    value={fields.company}
                    onChange={(e) =>
                      handleFieldsChange(e.target.value, "company")
                    }
                    onBlur={() => validator.current.showMessageFor("company")}
                    autoComplete="none"
                  />
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="row">
              <div className="col-3"></div>
              <div className="col-9">
                {validator.current.message(
                  "Company",
                  fields.company,
                  "required|text",
                  { className: "text-danger py-1" }
                )}
              </div>
            </div>
            <div className="row my-3">
              <div className="col-3 text-end">
                Tier
                <RedAsterick />
              </div>
              <div className="col-9">
                <Select
                  showSearch
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                  name="tier_type_id"
                  className={`fs-bold-14 ${Tablet ? "w-75" : "w-25"}`}
                  placeholder="Select Tier"
                  optionFilterProp="children"
                  value={fields.tier_type_id}
                  onChange={(val) => handleFieldsChange(val, "tier_type_id")}
                  onBlur={() =>
                    validator.current.showMessageFor("tier_type_id")
                  }
                  listHeight={120}
                  listItemHeight={4}
                  autoComplete="none"
                  disabled={
                    (userType && userType === "BRAND ADMIN") ||
                    fields?.user_type === "AGENT"
                      ? true
                      : false
                  }
                >
                  {tierList &&
                    tierList?.map((data) => {
                      return <Option value={data.tier_id}>{data.tier}</Option>;
                    })}
                </Select>
                {validator.current.message(
                  "tiers",
                  fields.tier_type_id,
                  "required|text",
                  { className: "text-danger py-1" }
                )}
              </div>
            </div>
            <div className="row my-3 border-bottom-gray pb-4">
              <div className="col-3 text-end">Bio</div>
              <div className="col-9">
                <TextArea
                  rows={6}
                  className={`fs-bold-14 ${Tablet ? "w-75" : "w-50"}`}
                  name="bio"
                  placeholder="Enter a Bio here"
                  value={fields.bio}
                  onChange={(e) => handleFieldsChange(e.target.value, "bio")}
                  onBlur={() => validator.current.showMessageFor("bio")}
                  autoComplete="none"
                  maxLength={TEXTAREA_TEXT_LIMITS.MAX}
                />
              </div>
            </div>

            <section>
              <div className="row">
                <div className="col-12 mt-4">
                  <h6 className="font-pink fw-bolder fs-20">
                    External & Social Links
                  </h6>
                </div>
              </div>

              <div className="row mt-1">
                <div className="col-12 mt-1">
                  <span className="fs-16">
                    NB: If you paste your details into these boxes then anyone
                    using this site will be able to view/contact you via these
                    platforms.
                  </span>
                </div>
              </div>

              <div className="social-media-div border-bottom-gray pb-4 mb-4">
                {socialMediaList &&
                  socialMediaList.map((data, idx) => (
                    <div className="row mt-4 ">
                      <div className="col-2 text-end ">
                        {data?.social_media}
                      </div>
                      <div
                        className={
                          Tablet ? "col-2 text-center" : "col-1 text-center "
                        }
                      >
                        <Switch
                          checked={
                            fields?.external_social_links_details?.some(
                              (social_media) =>
                                social_media.social_media_id ===
                                data.social_media_id
                            )
                              ? true
                              : false
                          }
                          onChange={(checked) =>
                            onHandleSocialSwitchChange(checked, idx)
                          }
                        />
                      </div>
                      <div
                        className={
                          Tablet ? "col-5 text-start" : "col-4 text-start "
                        }
                      >
                        <Input
                          placeholder="https://webaddress.com"
                          name={data?.social_media}
                          className="w-100 fs-bold-14"
                          disabled={
                            fields?.external_social_links_details?.some(
                              (ele) =>
                                ele.social_media_id === data.social_media_id
                            )
                              ? false
                              : true
                          }
                          value={
                            fields?.external_social_links_details?.some(
                              (social_media) =>
                                social_media.social_media_id ===
                                data.social_media_id
                            )
                              ? fields?.external_social_links_details[
                                  fields.external_social_links_details.findIndex(
                                    (social_media) =>
                                      social_media.social_media_id ===
                                      data.social_media_id
                                  )
                                ]["social_media_url"]
                              : ""
                          }
                          onChange={(event) =>
                            socialMediaHandleURLChange(idx, event.target.value)
                          }
                          onBlur={() =>
                            validator.current.showMessageFor("social_media")
                          }
                        />
                        {validator.current.message(
                          "social_media",
                          fields?.external_social_links_details?.some(
                            (social_media) =>
                              social_media.social_media_id ===
                              data.social_media_id
                          )
                            ? fields?.external_social_links_details[
                                fields.external_social_links_details.findIndex(
                                  (social_media) =>
                                    social_media.social_media_id ===
                                    data.social_media_id
                                )
                              ]["social_media_url"]
                            : "",
                          fields?.external_social_links_details?.some(
                            (social_media) =>
                              social_media.social_media_id ===
                              data.social_media_id
                          )
                            ? "required|url"
                            : "",
                          { className: "text-danger py-1" }
                        )}
                      </div>
                    </div>
                  ))}
              </div>
            </section>
          </div>
          <div
            className="shadow-sm p-3 d-flex justify-content-between"
            style={{ backgroundColor: "#eeeeee" }}
          >
            {props?.onCancelFromModal ? (
              <Button
                className="px-3 d-block button-pink rounded"
                onClick={() => {
                  props?.onCancelFromModal();
                  clearFormState();
                }}
              >
                Cancel
              </Button>
            ) : (
              <Link to="/usermanagement">
                <Button className="px-3 d-block button-pink rounded">
                  Cancel
                </Button>
              </Link>
            )}

            {props?.onSuccessFromModal ? (
              <Button type="primary" onClick={handleFormSubmit}>
                {!loading ? (
                  "Create User"
                ) : (
                  <div style={{ width: "80px" }}>
                    <Spin indicator={loadingIcon} style={{ color: "white" }} />
                  </div>
                )}
              </Button>
            ) : (
              <Button type="primary" onClick={handleFormSubmit}>
                {!loading ? (
                  "Add User"
                ) : (
                  <div style={{ width: "60px" }}>
                    <Spin indicator={loadingIcon} style={{ color: "white" }} />
                  </div>
                )}
              </Button>
            )}
          </div>
        </div>
      </form>
    );
};

export default AddUser;
